import React from 'react'
import { DatasetSearchInformation } from '../../types/dataverse/dataset'
import { CardColumns } from 'react-bootstrap'
import DatasetCard from '../molecules/DatasetCard'

interface LatestDatasetsGridProps {
  datasetSearchInformationList: DatasetSearchInformation[]
}

class DatasetsGrid extends React.Component<LatestDatasetsGridProps> {
  public render() {
    return (
      <div className={'latest-datasets-grid-container'}>
        <CardColumns>
          {this.getDatasetsCards(this.props.datasetSearchInformationList)}
        </CardColumns>
      </div>
    )
  }

  private getDatasetsCards(datasetSearchInformationList: DatasetSearchInformation[]): JSX.Element[] | string {

    const cards: JSX.Element[] = []

    if (!datasetSearchInformationList) {
      return ''
    }

    datasetSearchInformationList.forEach(datasetSeachInformation => {
      cards.push(<DatasetCard datasetSearchInformation={datasetSeachInformation} key={datasetSeachInformation.entity_id} />)
    })

    return cards
  }
}

export default DatasetsGrid