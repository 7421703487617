import { Auth } from 'aws-amplify'
import { push } from 'connected-react-router'
import React from 'react'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Nav from 'react-bootstrap/Nav'
import store from '../../stores/store'
import { getUserName, setAuthState } from '../../utils/AuthenticationUtil'
import { StoreState } from '../../types/storeState'
import { connect } from 'react-redux'
import { NetworkConstants } from '../../utils/NetworkConstants'
import Divider from '../atoms/Divider'

interface AuthenticationHeaderProps {
  isAuthenticated?: boolean
}

class AuthenticationHeader extends React.Component<AuthenticationHeaderProps> {
  public render() {
    return (
      <Nav.Item className='header-auth'>
        {this.props.isAuthenticated
          ? this.getAuthenticatedButtons()
          : this.getUnauthenticatedButtons()}
      </Nav.Item>
    )
  }

  private getAuthenticatedButtons(): React.ReactNode {
    return (
      <div>
        <Button onClick={this.onLogoutClicked} variant='link'>
          Logout
				</Button>
        <Divider />
        <DropdownButton
          variant='link'
          id='dropdown-basic-button'
          title={`User (${getUserName()})`}
          alignRight
        >
          <Dropdown.Item href={NetworkConstants.URL_CHANGE_PASSWORD}>
            Change password
					</Dropdown.Item>
        </DropdownButton>
      </div>
    )
  }

  private getUnauthenticatedButtons(): React.ReactNode {
    return (
      <div>
        <Button onClick={this.onLoginClicked} variant='link'>
          Login
				</Button>
        <Divider />
        <Button onClick={this.onSignupClicked} variant='link'>Sign Up</Button>
      </div>
    )
  }

  private onLoginClicked() {
    store.dispatch(push(NetworkConstants.URL_LOGIN))
  }

  private onLogoutClicked() {
    Auth.signOut({ global: true }).then(() => setAuthState(null))
    store.dispatch(push(NetworkConstants.URL_HOME))
  }

  private onSignupClicked() {
    store.dispatch(push(NetworkConstants.URL_SIGN_UP))
  }
}

const mapStateToProps = (state: StoreState): AuthenticationHeaderProps => {
  if (state && state.authentication) {
    return {
      isAuthenticated: state.authentication.auth
        ? state.authentication.auth.isAuthenticated
        : false
    }
  }

  return {
    isAuthenticated: false
  }
}

export default connect(mapStateToProps)(AuthenticationHeader)
