import React, { useState } from 'react'
import { DatasetInformation } from '../../types/dataverse/dataset'
import { Dropdown, Row } from 'react-bootstrap'
import Divider from '../atoms/Divider'
import { DatetimeUtil } from '../../utils/DatetimeUtil'
import { DatasetUtil } from '../../utils/DatasetUtil'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { DataverseService } from '../../api/dataverseService'
import Spinner from 'react-bootstrap/Spinner'

interface DatasetMetadataState {
  datasetsInformation: DatasetInformation[]
  loadingVersions: boolean
}

interface DatasetMetadataProps {
  datasetInformation: DatasetInformation
  onSelectDatasetVersionCallback: any
  latestDatasetInformation: DatasetInformation
}

const DatasetMetadata = ({ datasetInformation, onSelectDatasetVersionCallback, latestDatasetInformation }: DatasetMetadataProps): JSX.Element => {
  const [state, setState] = useState<DatasetMetadataState>({ datasetsInformation: [], loadingVersions: false })
  const keywords: string = DatasetUtil.getKeywords(datasetInformation)
  const currentVersion: string = DatasetUtil.getVersion(datasetInformation)
  const numberOfFiles: number = DatasetUtil.getNumberOfFiles(datasetInformation)
  const numberOfFilesText: string = DatasetUtil.getNumberOfFilesText(numberOfFiles)
  const datasetDateText: string = DatetimeUtil.getMonthYearDate(datasetInformation.updatedAt)

  const loadDatasetVersion = (event: React.MouseEvent<any>, index: number) => {
    event.preventDefault()
    onSelectDatasetVersionCallback(state.datasetsInformation[index])
  }

  const loadDatasetVersions = () => {
    if (state.datasetsInformation.length === 0) {
      setState((prevState: DatasetMetadataState) => ({
        ...prevState,
        loadingVersions: true
      }))

      DataverseService
        .getDatasetVersions(datasetInformation.id)
        .then(result => {
          setState((prevState: DatasetMetadataState) => ({
            ...prevState,
            datasetsInformation: result,
            loadingVersions: false
          }))
        })
        .catch(_ => {
          setState((prevState: DatasetMetadataState) => ({
            ...prevState,
            datasetsInformation: [],
            loadingVersions: false
          }))
        })
    }
  }

  const dropdownVersionText = (currentVersion: string): JSX.Element => {
    const latestVersion = DatasetUtil.getVersion(latestDatasetInformation)

    if (latestVersion === currentVersion) {
      return (
        <span>
          v{latestVersion} <b>(Latest)</b>
        </span>
      )
    }

    return <span>v{currentVersion}</span>
  }

  const getDropdownItems = (): JSX.Element[] => {
    const versionNumbers = DatasetUtil.getVersionNumberList(state.datasetsInformation)

    return versionNumbers.map((versionNumber, index) => {
      return (
        <Dropdown.Item key={index} onClick={(event: React.MouseEvent<any>) => {
          loadDatasetVersion(event, index)
        }}>
          {dropdownVersionText(versionNumber)}
        </Dropdown.Item>
      )
    })
  }

  const renderVersionsDropdown = (): JSX.Element => {
    return (
      <div className={'dataset-metadata-version-dropdown'}>
        <DropdownButton variant='link'
          id='dropdown-versions-button'
          title={`Version ${currentVersion}`}
          className='dataset-versions-dropdown'
          size='sm'
          onClick={() => loadDatasetVersions()}
        >
          {
            state.loadingVersions ?
              <div className={'dataset-versions-dropdown-spinner-container'}>
                <Spinner animation="border" />
              </div> :
              <div className={'dataset-versions-dropdown-menu'}>
                {getDropdownItems()}
              </div>
          }
        </DropdownButton>
      </div>
    )
  }

  return (
    <Row className={'dataset-metadata'}>
      {renderVersionsDropdown()}
      <Divider />
      <div className={'dataset-metadata-number-of-files'}>
        {numberOfFilesText}
      </div>
      <Divider />
      <div className={'dataset-metadata-date'}>
        {`Date: ${datasetDateText}`}
      </div>
      <Divider />
      <div className={'dataset-metadata-keywords'}>
        {keywords.length > 0 ? `Keywords: ${keywords}` : ''}
      </div>
    </Row>
  )
}

export default DatasetMetadata