import { Auth } from 'aws-amplify'
import { push } from 'connected-react-router'
import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'
import { Link } from 'react-router-dom'
import store from '../../../stores/store'
import { isAuthenticated } from '../../../utils/AuthenticationUtil'
import { NetworkConstants } from '../../../utils/NetworkConstants'
import FormInput from '../../atoms/FormInput'
import LoadingOverlay from '../../molecules/LoadingOverlay'
import { AlertConstants } from '../../../utils/AlertConstants'
import Helmet from 'react-helmet'

interface SignupState {
  user: {
    username: string
    email: string
    password: string
  }
  error?: {
    isShowing: boolean
    message?: string
  }
  loading?: {
    isShowing: boolean
    message?: string
  }
  codeVerification?: {
    show: boolean
    message?: string
  }
}

const defaultState: SignupState = {
  user: { username: '', email: '', password: '' },
  codeVerification: { show: false }
}

class Signup extends React.Component<any, SignupState> {
  public constructor(props: any) {
    super(props)

    this.state = defaultState

    this.onSignupClicked = this.onSignupClicked.bind(this)
    this.handleUsernameInputChange = this.handleUsernameInputChange.bind(this)
    this.handleEmailInputChange = this.handleEmailInputChange.bind(this)
    this.handlePasswordInputChange = this.handlePasswordInputChange.bind(this)
  }

  public render(): JSX.Element {
    if (isAuthenticated()) {
      store.dispatch(push(NetworkConstants.URL_HOME))
    }

    if (this.state.codeVerification && this.state.codeVerification.show) {
      store.dispatch(
        push({
          pathname: NetworkConstants.URL_CODE_VERIFICATION,
          state: {
            username: this.state.user.username,
            infoMessage: this.state.codeVerification.message
          }
        })
      )
    }

    return (
      <div className="signup">
        <Helmet>
          <title>AHEAD - Signup</title>
        </Helmet>
        <LoadingOverlay
          isShowing={this.state.loading ? this.state.loading.isShowing : false}
          loadingText={this.state.loading ? this.state.loading.message : ''}
        />

        {this.getAlerts()}

        <h1>Sign up</h1>
        <Form
          className="d-flex flex-column mt-3"
          onSubmit={this.onSignupClicked}
        >
          <FormGroup>
            <FormInput
              type="text"
              placeholder="Username"
              callback={this.handleUsernameInputChange}
              value={this.state.user.username}
              aria-label={'Username input'}
            />
          </FormGroup>

          <FormGroup>
            <FormInput
              type="text"
              placeholder="Email"
              callback={this.handleEmailInputChange}
              value={this.state.user.email}
              aria-label={'Email input'}
            />
          </FormGroup>

          <FormGroup>
            <FormInput
              type="password"
              placeholder="Password"
              callback={this.handlePasswordInputChange}
              value={this.state.user.password}
              aria-label={'Password input'}
            />
          </FormGroup>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <div className="signup-activate-user mt-sm-4">
          <Link to={NetworkConstants.URL_CODE_VERIFICATION}>
            I want to activate user
          </Link>
        </div>
      </div>
    )
  }

  private getAlerts(): JSX.Element {
    return (
      <div className="signup-alerts">
        <Alert
          variant="danger"
          show={this.state.error ? this.state.error.isShowing : false}
        >
          {this.state.error ? this.state.error.message : ''}
        </Alert>
      </div>
    )
  }

  private onSignupClicked(event: React.ChangeEvent<any>): void {
    event.preventDefault()

    this.setState({ loading: { isShowing: true } })

    Auth.signUp(
      this.state.user.username,
      this.state.user.password,
      this.state.user.email
    )
      .then(data => {
        this.setState({
          user: {
            username: this.state.user.username,
            email: '',
            password: ''
          },
          error: undefined,
          loading: undefined,
          codeVerification: {
            show: true,
            message: `Your verification code has been sent to: ${
              data.codeDeliveryDetails.Destination
              }`
          }
        })
      })
      .catch(err => {
        this.setState({
          error: {
            isShowing: true,
            message: err.message ? err.message : AlertConstants.GENERIC_ERROR
          },
          loading: undefined
        })
      })
  }

  private handleUsernameInputChange(input: string): void {
    this.setState(prevState => ({
      user: {
        username: input,
        email: prevState.user.email,
        password: prevState.user.password
      },
      error: undefined
    }))
  }

  private handleEmailInputChange(input: string): void {
    this.setState(prevState => ({
      user: {
        username: prevState.user.username,
        email: input,
        password: prevState.user.password
      },
      error: undefined
    }))
  }

  private handlePasswordInputChange(input: string): void {
    this.setState(prevState => ({
      user: {
        username: prevState.user.username,
        email: prevState.user.email,
        password: input
      },
      error: undefined
    }))
  }
}
export default Signup
