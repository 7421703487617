import React from 'react'
import aboutJson from '../../assets/about.json'
import { Col, Row } from 'react-bootstrap'
import LinksList from '../molecules/LinksList'
import PostHeader from '../molecules/PostHeader'
import PostBody from '../molecules/PostBody'
import Helmet from 'react-helmet'

class About extends React.Component {
  public render() {
    return (
      <div className="about">
        <Helmet>
          <title>AHEAD - About</title>
        </Helmet>
        <Row>
          <Col className={'about-header'} md={{ span: 6, offset: 1 }}>
            <PostHeader title={aboutJson.title} subtitle={aboutJson.subtitle ? aboutJson.subtitle : undefined} />
          </Col>
        </Row>
        <Row>
          <Col className={'about-content'} md={{ span: 5, offset: 1 }}>
            <PostBody body={aboutJson.body} />
          </Col>
          <Col className={'about-links'} md={{ span: 4, offset: 2 }}>
            <LinksList title={aboutJson.contactLinks.title} links={aboutJson.contactLinks.links} />
            <LinksList title={aboutJson.socialNetworkLinks.title} links={aboutJson.socialNetworkLinks.links} />
          </Col>
        </Row>
      </div>
    )
  }
}

export default About
