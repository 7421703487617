import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import authentication from './auth'

const rootReducers = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    authentication
  })

export default rootReducers
