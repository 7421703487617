import React from 'react'
import { Col } from 'react-bootstrap'
import Helmet from 'react-helmet'

class NotFound extends React.Component {
  public render() {
    return (
      <div className="not-found">
        <Helmet>
          <title>AHEAD - Not found</title>
        </Helmet>
        <Col md={{ span: 4, offset: 1 }}>
          <h1>404 Error</h1>
          <br />Please redirect to a valid url.
        </Col>
      </div>
    )
  }
}

export default NotFound
