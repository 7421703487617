export class NetworkConstants {
  public static URL_HOME = '/'
  public static URL_ABOUT = '/about'
  public static URL_LOGIN = '/login'
  public static URL_SIGN_UP = '/sign-up'
  public static URL_CHANGE_PASSWORD = '/change-password'
  public static URL_FORGOT_PASSWORD = '/forgot-password'
  public static URL_CODE_VERIFICATION = '/code-verification'
  public static URL_SEARCH = '/search'
  public static URL_DATASET = '/dataset'
  public static URL_DATA_CURATOR_TOOL = '/data-curator-tool'
  public static ROUTE_DATASET = '/dataset/:datasetId'
}
