import React from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { TABLE_DEFAULT_PAGE_SIZE } from '../../utils/Constants'

interface AheadTableProps {
  columns: any[]
  data: any[]
  currentPage?: number
  onPageChangeCallback: (currentPage: number) => void
}

const AheadTable = ({ columns, data, currentPage, onPageChangeCallback }: AheadTableProps): JSX.Element => {
  return (
    <ReactTable
      pageSize={TABLE_DEFAULT_PAGE_SIZE}
      page={currentPage ? currentPage : 0}
      onPageChange={onPageChangeCallback}
      showPageSizeOptions={false}
      data={data}
      columns={columns}
      className={'-striped -highlight'}
    />
  )
}

export default AheadTable