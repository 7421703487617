import {
  PAGINATION_FIRST_PAGE_INDEX,
  PAGINATION_MAX_PAGES_DISPLAYED
} from './Constants'

export class PaginationUtil {
  public static getMaxItemsDisplayed(numberOfPages: number): number {
    return numberOfPages > PAGINATION_MAX_PAGES_DISPLAYED ? PAGINATION_MAX_PAGES_DISPLAYED : numberOfPages
  }

  public static shouldDisablePaginationBackward(currentPage: number, numberOfPages: number) {
    return currentPage <= PAGINATION_FIRST_PAGE_INDEX || currentPage > numberOfPages
  }

  public static shouldShowLeftEllipsis(currentPage: number, numberOfPages: number) {
    const maxItemsDisplayed: number = this.getMaxItemsDisplayed(numberOfPages)
    return currentPage > 3 && numberOfPages > maxItemsDisplayed
  }

  public static shouldShowRightEllipsis(currentPage: number, numberOfPages: number) {
    const maxItemsDisplayed: number = this.getMaxItemsDisplayed(numberOfPages)
    return numberOfPages - currentPage > 2 && numberOfPages > maxItemsDisplayed
  }

  public static shouldDisablePaginationForward(currentPage: number, numberOfPages: number) {
    return currentPage < PAGINATION_FIRST_PAGE_INDEX || currentPage >= numberOfPages
  }

  public static getStartingPosition(currentPage: number, numberOfPages: number, maxItemsDisplayed: number) {
    if (numberOfPages < currentPage || currentPage < PAGINATION_FIRST_PAGE_INDEX) {
      return 0
    }

    if (currentPage === numberOfPages) {
      return currentPage - maxItemsDisplayed + 1
    }

    if (numberOfPages - currentPage === 1) {
      return numberOfPages - maxItemsDisplayed + 1
    }

    if (currentPage === PAGINATION_FIRST_PAGE_INDEX || currentPage <= 2) {
      return PAGINATION_FIRST_PAGE_INDEX
    }

    return currentPage - 2
  }

  public static getFirstPageStartingPosition(): number {
    return 0
  }

  public static getPreviousPageStartingPosition(currentStartPosition: number, itemsPerPage: number): number {
    return currentStartPosition - itemsPerPage
  }

  public static getSelectedPageStartingPosition(pageIndex: number, itemsPerPage: number): number {
    return (pageIndex - PAGINATION_FIRST_PAGE_INDEX) * itemsPerPage
  }

  public static getNextPageStartingPosition(currentStartPosition: number, itemsPerPage: number): number {
    return currentStartPosition + itemsPerPage
  }

  public static getLastPageStartingPosition(totalCount: number, itemsPerPage: number) {
    const targetPage: number = Math.floor(totalCount / itemsPerPage)
    return targetPage * itemsPerPage === totalCount ? (targetPage - 1) * itemsPerPage : targetPage * itemsPerPage
  }

  public static getCurrentResultsCountText(resultsLength: number, currentPageIndex: number, itemsPerPage: number, totalCount: number) {
    if (!resultsLength) {
      return '0 results'
    }

    if (currentPageIndex <= 0) {
      return ''
    }

    const startingItem: number = (currentPageIndex - 1) * itemsPerPage
    return `${startingItem}-${startingItem + resultsLength} of ${totalCount} results`
  }
}