import React from 'react'
import SearchInput from '../atoms/SearchInput'
import Col from 'react-bootstrap/Col'
import { Row } from 'react-bootstrap'
import SearchList from '../organisms/SearchList'
import { DatasetSearchInformation } from '../../types/dataverse/dataset'
import { DataverseService } from '../../api/dataverseService'
import Divider from '../atoms/Divider'
import PaginationBar from '../molecules/PaginationBar'
import { SearchOrder, SearchResponse, SearchSortAttribute } from '../../types/dataverse/search'
import { PAGINATION_DEFAULT_ITEMS_PER_PAGE, PAGINATION_FIRST_PAGE_INDEX } from '../../utils/Constants'
import { PaginationUtil } from '../../utils/PaginationUtil'
import SortDropdown from '../molecules/SortDropdown'
import { LoadingState } from '../../types/loadingState'
import LoadingOverlay from '../molecules/LoadingOverlay'
import Helmet from 'react-helmet'

interface SearchResultsProps {
  query: string
  location?: any
}

interface SearchResultsState {
  overlay: LoadingState,
  currentPageIndex: number
  itemsPerPage: number
  query: string
  datasetSearchInformationList: DatasetSearchInformation[]
  totalCount: number
  startPosition: number
  sortBy: SearchSortAttribute
  sortOrder: SearchOrder
}

const defaultState: SearchResultsState = {
  overlay: { isShowing: false },
  currentPageIndex: PAGINATION_FIRST_PAGE_INDEX,
  itemsPerPage: PAGINATION_DEFAULT_ITEMS_PER_PAGE,
  query: '',
  datasetSearchInformationList: [],
  totalCount: 0,
  startPosition: 0,
  sortBy: SearchSortAttribute.DEFAULT,
  sortOrder: SearchOrder.DEFAULT
}

class Search extends React.Component<SearchResultsProps, SearchResultsState> {
  public constructor(props: any) {
    super(props)

    this.state = defaultState

    if (props.location.state !== undefined) {
      this.state = {
        ...this.state,
        query: props.location.state.query ? props.location.state.query : '*'
      }
    }
  }

  public componentDidMount() {
    if (this.state.query) {
      this.searchQuery(this.state.query)
    }
  }

  private searchQuery = (query: string, startPosition = 0) => {
    this.setState({
      overlay: { isShowing: true }
    })

    this.search(query, startPosition)
      .then((result: SearchResponse) => {
        this.setState({
          overlay: { isShowing: false },
          datasetSearchInformationList: result.items,
          totalCount: result.totalCount,
          startPosition: result.startPosition,
          query: result.query,
          currentPageIndex: result.startPosition / this.state.itemsPerPage + PAGINATION_FIRST_PAGE_INDEX
        })
        window.scrollTo(0, 0)
      })
      .catch(() => {
        this.setState({
          overlay: { isShowing: false }
        })
      })
  }

  private search = (query: string, startPosition: number): Promise<any> => {
    const { sortBy, sortOrder } = this.state

    return DataverseService.search(query, startPosition, sortBy, sortOrder)
  }

  private handleSortDataset(sortBy: SearchSortAttribute, sortOrder: SearchOrder): void {
    const { query, startPosition } = this.state

    this.setState({ sortBy, sortOrder }, () => {
      this.searchQuery(query, startPosition)
    })
  }

  private renderPagination = (): JSX.Element | string => {
    const datasetSearchInformationList: DatasetSearchInformation[] = this.state.datasetSearchInformationList
    if (datasetSearchInformationList && this.state.totalCount > this.state.itemsPerPage) {
      return (
        <Row className='search-results-pagination'>
          <Col md={{ span: 10, offset: 1 }}>
            <PaginationBar
              currentPage={this.state.currentPageIndex}
              numberOfPages={Math.ceil(this.state.totalCount / this.state.itemsPerPage)}
              firstPageCallback={() => this.firstPageCallback()}
              previousPageCallback={() => this.previousPageCallback()}
              pageSelectedCallback={(pageIndex: number) => this.pageSelectedCallback(pageIndex)}
              nextPageCallback={() => this.nextPageCallback()}
              lastPageCallback={() => this.lastPageCallback()}
            />
          </Col>
        </Row>
      )
    } else {
      return ''
    }
  }

  public render() {
    const datasetSearchInformationList: DatasetSearchInformation[] = this.state.datasetSearchInformationList
    return <div className='search'>
      <Helmet>
        <title>AHEAD - Search</title>
      </Helmet>
      <Row className='search-input'>
        <Col md={{ span: 4, offset: 1 }}>
          <SearchInput onSubmitCallback={this.searchQuery} defaultInput={this.state.query} />
        </Col>
      </Row>
      <Row className='search-options'>
        <Col md={{ span: 10, offset: 1 }}>
          <div className='search-options-results'>
            <span className='search-options-datasets-count'>
              {PaginationUtil.getCurrentResultsCountText(datasetSearchInformationList.length,
                this.state.currentPageIndex, this.state.itemsPerPage, this.state.totalCount)}
            </span>
            <Divider />
            <SortDropdown onSortCallback={(sortBy: SearchSortAttribute, sortOrder: SearchOrder) => this.handleSortDataset(sortBy, sortOrder)} />
          </div>
        </Col>
      </Row>
      <Row className='search-results-body'>
        <Col md={{ span: 10, offset: 1 }}>
          {
            this.state.datasetSearchInformationList.length > 0 ?
              <SearchList datasetSearchInformationList={datasetSearchInformationList} /> :
              <span className={'search-results-body-no-results-text'}>No results available</span>
          }
        </Col>
      </Row>
      {this.renderPagination()}
      <LoadingOverlay isShowing={this.state.overlay.isShowing} />
    </div>
  }

  private firstPageCallback(): void {
    this.searchQuery(this.state.query, PaginationUtil.getFirstPageStartingPosition())
  }

  private previousPageCallback(): void {
    this.searchQuery(this.state.query, PaginationUtil.getPreviousPageStartingPosition(this.state.startPosition, this.state.itemsPerPage))
  }

  private pageSelectedCallback(pageIndex: number): void {
    this.searchQuery(this.state.query, PaginationUtil.getSelectedPageStartingPosition(pageIndex, this.state.itemsPerPage))
  }

  private nextPageCallback(): void {
    this.searchQuery(this.state.query, PaginationUtil.getNextPageStartingPosition(this.state.startPosition, this.state.itemsPerPage))
  }

  private lastPageCallback(): void {
    this.searchQuery(this.state.query, PaginationUtil.getLastPageStartingPosition(this.state.totalCount, this.state.itemsPerPage))
  }
}

export default Search