import React from 'react'
import { Button, Row } from 'react-bootstrap'
import closeEditorModal from '../../assets/close_editor_modal.json'
import store from '../../stores/store'
import { goBack } from 'connected-react-router'
import AheadModal from './AheadModal'

interface EditorCloseModalProps {
  show: boolean
  closeButtonCallback: () => void
}

class EditorCloseModal extends React.Component<EditorCloseModalProps> {
  public render() {
    return (
      <AheadModal
        show={this.props.show}
        title={closeEditorModal.title}
        body={closeEditorModal.body}
        signature={closeEditorModal.signature}
        closeButtonCallback={() => this.props.closeButtonCallback()}>
        <Row className={'ahead-modal-action-buttons'}>
          <Button
            className={'ahead-modal-action-close-editor-close'}
            onClick={() => store.dispatch(goBack())}>Close editor</Button>
          <Button
            className={'ahead-modal-action-cancel'}
            onClick={this.props.closeButtonCallback}
            variant={'secondary'}>Cancel</Button>
        </Row>
      </AheadModal>
    )
  }
}

export default EditorCloseModal