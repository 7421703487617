import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import AuthenticationHeader from '../molecules/AuthenticationHeader'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { Col } from 'react-bootstrap'
import { AssetsConstants } from '../../utils/AssetsConstants'
import Divider from '../atoms/Divider'

class Header extends React.Component {
  public render() {
    return (
      <div className="header">
        <Navbar expand="lg" className="p-3" aria-label="Header navigation bar">
          <Col xs={2}>
            <Navbar.Brand href={NetworkConstants.URL_HOME}>
              <img
                alt="Automated Historical Editable Archive of Datasets logo"
                src={AssetsConstants.LOGO_URL}
                className="d-inline-block align-top brand-logo"
              />
            </Navbar.Brand>
          </Col>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Col xs={{ span: 4, offset: 4 }}>
              <Nav className="mr-auto col-md-6">
                <Nav.Link href={NetworkConstants.URL_HOME}>Search</Nav.Link>
                <Divider />
                <Nav.Link href={NetworkConstants.URL_ABOUT}>About</Nav.Link>
              </Nav>
            </Col>
            <Col xs={{ span: 3 }}>
              <AuthenticationHeader />
            </Col>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

export default Header
