import { Dropdown } from 'react-bootstrap'
import DropdownButton from 'react-bootstrap/DropdownButton'
import React from 'react'
import { SearchOrder, SearchSortAttribute } from '../../types/dataverse/search'

interface SortDropdownProps {
  onSortCallback?: any
}

class SortDropdown extends React.Component<SortDropdownProps, {}> {
  private relevantDatasets = (): void => {
    this.props.onSortCallback()
  }

  private newestDatasets = (): void => {
    this.props.onSortCallback(SearchSortAttribute.DATE, SearchOrder.DESC)
  }

  private oldestDatasets = (): void => {
    this.props.onSortCallback(SearchSortAttribute.DATE, SearchOrder.ASC)
  }

  private nameSortDatasets = (): void => {
    console.log(SearchSortAttribute.NAME)
    this.props.onSortCallback(SearchSortAttribute.NAME, SearchOrder.ASC)
  }

  private reverseNameSortDatasets = (): void => {
    this.props.onSortCallback(SearchSortAttribute.NAME, SearchOrder.DESC)
  }

  public render() {
    return (
      <DropdownButton variant='link' id='dropdown-basic-button' title='Sort' className='sort-dropdown' size='sm'>
        <Dropdown.Item onClick={this.relevantDatasets}>Relevance</Dropdown.Item>
        <Dropdown.Item onClick={this.newestDatasets}>Newest</Dropdown.Item>
        <Dropdown.Item onClick={this.oldestDatasets}>Oldest</Dropdown.Item>
        <Dropdown.Item onClick={this.nameSortDatasets}>Name (A-Z)</Dropdown.Item>
        <Dropdown.Item onClick={this.reverseNameSortDatasets}>Name (Z-A)</Dropdown.Item>
      </DropdownButton>
    )
  }
}

export default SortDropdown