import React from 'react'
import { Button, Row } from 'react-bootstrap'
import submitNewVersionModal from '../../assets/submit_new_version_modal.json'
import AheadModal from './AheadModal'

interface EditorSubmitModalProps {
  show: boolean
  closeButtonCallback: () => void
  submitNewVersionCallback?: any
}

class EditorSubmitVersionModal extends React.Component<EditorSubmitModalProps> {
  public render() {
    return (
      <AheadModal
        show={this.props.show}
        title={submitNewVersionModal.title}
        body={submitNewVersionModal.body}
        signature={submitNewVersionModal.signature}
        closeButtonCallback={() => this.props.closeButtonCallback()}>
        <Row className={'ahead-modal-action-buttons'}>
          <Button
            className={'ahead-modal-action-submit-new-version'}
            variant={'primary'}
            onClick={this.props.submitNewVersionCallback}>Submit new version</Button>
          <Button
            className={'ahead-modal-action-cancel'}
            onClick={this.props.closeButtonCallback}
            variant={'secondary'}>Cancel</Button>
        </Row>
      </AheadModal>
    )
  }
}

export default EditorSubmitVersionModal