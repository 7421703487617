import React from 'react'
import { FormControl } from 'react-bootstrap'

interface FormInputProps {
  type?: string | undefined
  placeholder?: string | undefined
  value: string
  callback: any
}

const FormInput = ({ type, placeholder, value, callback }: FormInputProps): JSX.Element => {
  const inputRef: React.RefObject<any> = React.createRef()

  return (
    <FormControl
      type={type ? type : 'text'}
      placeholder={placeholder ? placeholder : ''}
      ref={inputRef}
      onChange={() => callback(inputRef.current.value)}
      value={value}
      aria-label={`${placeholder} input`}
    />
  )
}

export default FormInput
