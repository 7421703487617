import { DatasetSearchInformation } from '../types/dataverse/dataset'
import {
  FIRST_CHARACTER,
  TEXT_DATASET_DESCRIPTION_MAX_LENGTH,
  TEXT_DATASET_TITLE_MAX_LENGTH,
  VERSION_DEFAULT_MAJOR_VERSION, VERSION_DEFAULT_MINOR_VERSION
} from './Constants'

export class SearchUtil {
  public static getKeywords(datasetSearchInformation: DatasetSearchInformation, separator = ', '): string {
    if (!datasetSearchInformation.keywords) {
      return ''
    }

    return datasetSearchInformation.keywords.join(separator)
  }

  public static getVersion(datasetSearchInformation: DatasetSearchInformation): string {
    if (datasetSearchInformation.majorVersion === undefined || datasetSearchInformation.minorVersion === undefined) {
      return ''
    }

    return `${datasetSearchInformation.majorVersion}.${datasetSearchInformation.minorVersion}`
  }

  static getTrimmedTitle(datasetSearchInformation: DatasetSearchInformation) {
    if (!datasetSearchInformation.name) {
      return ''
    }

    const title: string = datasetSearchInformation.name

    if (title.length > TEXT_DATASET_TITLE_MAX_LENGTH) {
      return `${title.substring(FIRST_CHARACTER, TEXT_DATASET_TITLE_MAX_LENGTH)}...`
    }

    return title
  }

  static getTrimmedDescription(datasetSearchInformation: DatasetSearchInformation) {
    if (!datasetSearchInformation.description) {
      return ''
    }

    const descriptionText: string = datasetSearchInformation.description

    if (descriptionText.length > TEXT_DATASET_DESCRIPTION_MAX_LENGTH) {
      return `${descriptionText.substring(FIRST_CHARACTER, TEXT_DATASET_DESCRIPTION_MAX_LENGTH)}...`
    }

    return descriptionText
  }

  static isOriginalVersion(datasetSearchInformation: DatasetSearchInformation) {
    const versionNumber = datasetSearchInformation.majorVersion
    const versionMinorNumber = datasetSearchInformation.minorVersion

    if (!versionNumber) {
      return true
    }

    if (versionNumber === VERSION_DEFAULT_MAJOR_VERSION && versionMinorNumber === VERSION_DEFAULT_MINOR_VERSION) {
      return true
    }

    return false
  }
}