import React from 'react'
import { Row } from 'react-bootstrap'


interface DatasetHeaderProps {
  title?: string
}

class DatasetHeader extends React.Component<DatasetHeaderProps> {
  public render() {
    return <Row className={'dataset-header-title'}>
      <h1>{this.props.title ? this.props.title : ''}</h1>
    </Row>
  }
}

export default DatasetHeader