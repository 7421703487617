import React from 'react'
import { Button } from 'react-bootstrap'

interface ButtonWithIconProps {
  buttonText: string,
  altText: string
  icon: string,
  hoverIcon: string,
  onClickCallback: any
}

const ButtonWithIcon = (props: ButtonWithIconProps): JSX.Element => {
  return (
    <Button className={'button-with-icon'} variant="link" onClick={(event: any) => {
      event.preventDefault()
      props.onClickCallback()
    }}>
      <img src={props.icon} alt={props.altText} className={'img'} />
      <img src={props.hoverIcon} alt={props.altText} className={'img-hover'} />
      {props.buttonText}
    </Button>
  )
}

export default ButtonWithIcon