import React from 'react'
import { ModalInformationBody } from '../../types/modal'
import AheadModal from './AheadModal'

interface InformationModalProps {
  show: boolean
  informationBody?: ModalInformationBody
  closeButtonCallback: () => void
}

class InformationModal extends React.Component<InformationModalProps> {
  public render() {
    const informationBody = this.props.informationBody ? this.props.informationBody : { title: '', body: '' }

    return (
      <AheadModal
        show={this.props.show}
        title={informationBody.title}
        body={informationBody.body}
        closeButtonCallback={() => this.props.closeButtonCallback()} />
    )
  }
}

export default InformationModal