import React from 'react'
import { DatasetSearchInformation } from '../../types/dataverse/dataset'
import { Row } from 'react-bootstrap'
import { DatetimeUtil } from '../../utils/DatetimeUtil'
import { DatasetUtil } from '../../utils/DatasetUtil'
import store from '../../stores/store'
import { push } from 'connected-react-router'
import { SearchUtil } from '../../utils/SearchUtil'

interface SearchListItemProps {
  datasetSearchInformation: DatasetSearchInformation
}

class SearchListItem extends React.Component<SearchListItemProps> {
  public render() {
    const datasetSearchInformation: DatasetSearchInformation = this.props.datasetSearchInformation

    const keywords: string = SearchUtil.getKeywords(datasetSearchInformation)
    const lastUpdatedMonthYear: string = DatetimeUtil.getMonthYearDate(datasetSearchInformation.updatedAt)
    const numberOfFiles: number = datasetSearchInformation.fileCount

    return (
      <div className={'search-list-item'} onClick={() => this.onListItemClicked(datasetSearchInformation)}
        style={{ cursor: 'pointer' }}>
        <Row className='search-dataset-title'>
          {SearchUtil.getTrimmedTitle(datasetSearchInformation)}
        </Row>
        <Row className={'search-dataset-version'}>
          {this.getVersion(datasetSearchInformation)}
        </Row>
        <Row
          className='search-dataset-description'>{SearchUtil.getTrimmedDescription(datasetSearchInformation)}
        </Row>
        <Row
          className='search-dataset-metadata'>{DatasetUtil.getMetadataText(lastUpdatedMonthYear, numberOfFiles, keywords)}
        </Row>
      </div>
    )
  }

  private getVersion(datasetSearchInformation: DatasetSearchInformation): JSX.Element | string {
    if (SearchUtil.isOriginalVersion(datasetSearchInformation)) {
      return ''
    }

    const versionState: string = DatasetUtil.getVersionState(datasetSearchInformation.versionState)

    return <div>version {SearchUtil.getVersion(datasetSearchInformation)}<span className={versionState}>{versionState}</span></div>
  }

  private onListItemClicked(datasetSearchInformation: DatasetSearchInformation) {
    store.dispatch(push({
      pathname: `/dataset/${datasetSearchInformation.entity_id}`
    }))
  }
}

export default SearchListItem