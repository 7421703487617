import React from 'react'
import { AssetsConstants } from '../../utils/AssetsConstants'
import { Button, Row } from 'react-bootstrap'

interface ModalCloseIconProps {
  onCloseIconClicked: () => void
  altText: string
}

const ModalCloseIcon = ({ onCloseIconClicked, altText }: ModalCloseIconProps): JSX.Element => {
  return (
    <Row className={'ahead-modal-close'}>
      <Button className={'ahead-modal-close-button'} variant={'link'} onClick={onCloseIconClicked}>
        <img className={'ahead-modal-close-button-image'} src={AssetsConstants.GREEN_CROSS_ICON_URL}
          alt={altText} />
      </Button>
    </Row>
  )
}

export default ModalCloseIcon