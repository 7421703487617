import React from 'react'
import { Row } from 'react-bootstrap'
import { DatasetInformationDescription } from '../../types/dataverse/dataset'

interface DatasetDescriptionProps {
  description?: DatasetInformationDescription
}

class DatasetDescription extends React.Component<DatasetDescriptionProps> {
  public render() {
    return (
      <Row className={'dataset-description'}>
        {this.props.description ? this.props.description.text : ''}
      </Row>
    )
  }
}

export default DatasetDescription