import { Auth } from 'aws-amplify'
import { push } from 'connected-react-router'
import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'
import store from '../../../stores/store'
import { NetworkConstants } from '../../../utils/NetworkConstants'
import {
  getInitialErrorMessage,
  getInitialInfoMessage,
  getInitialUsername
} from '../../../utils/PropsUtil'
import FormInput from '../../atoms/FormInput'
import LoadingOverlay from '../../molecules/LoadingOverlay'
import { AlertVariants } from '../../../utils/AlertUtils'
import { AlertConstants } from '../../../utils/AlertConstants'
import Helmet from 'react-helmet'

interface CodeVerificationState {
  username: string
  code: string
  loading?: { isShowing: boolean; message?: string }
  error?: { isShowing: boolean; message?: string }
  info?: { isShowing: boolean; message?: string }
}

interface CodeVerificationProps {
  location: {
    state: {
      infoMessage?: string
      errorMessage?: string
      username?: string
    }
  }
}

class CodeVerification extends React.Component<
  CodeVerificationProps,
  CodeVerificationState
  > {
  public constructor(props: any) {
    super(props)

    this.state = {
      username: getInitialUsername(this.props.location.state),
      code: '',
      loading: undefined,
      error: getInitialErrorMessage(this.props.location.state),
      info: getInitialInfoMessage(this.props.location.state)
    }

    this.handleUsernameInputChange = this.handleUsernameInputChange.bind(this)
    this.handleCodeInputChange = this.handleCodeInputChange.bind(this)

    this.onVerifyClicked = this.onVerifyClicked.bind(this)
    this.onResendCodeClicked = this.onResendCodeClicked.bind(this)
  }

  public render() {
    return (
      <div className="code-verification">
        <Helmet>
          <title>AHEAD - Code verification</title>
        </Helmet>
        <LoadingOverlay
          isShowing={this.state.loading ? this.state.loading.isShowing : false}
          loadingText={this.state.loading ? this.state.loading.message : ''}
        />

        {this.getAlerts()}

        <h1>User verification</h1>

        <Form
          className="d-flex flex-column mt-3"
          onSubmit={this.onVerifyClicked}
        >
          <FormGroup>
            <FormInput
              type="text"
              placeholder="Username"
              callback={this.handleUsernameInputChange}
              value={this.state.username}
            />
          </FormGroup>

          <FormGroup>
            <FormInput
              type="text"
              placeholder="Verification code"
              callback={this.handleCodeInputChange}
              value={this.state.code}
            />
          </FormGroup>

          <Button variant="primary" type="submit">
            Verify user
          </Button>
          <Button
            className="mt-3"
            variant="secondary"
            onClick={this.onResendCodeClicked}
          >
            Resend code
          </Button>
        </Form>
      </div>
    )
  }

  private getAlerts(): JSX.Element {
    return (
      <div className="code-verification-alerts">
        <Alert
          variant="danger"
          show={this.state.error ? this.state.error.isShowing : false}
        >
          {this.state.error ? this.state.error.message : ''}
        </Alert>

        <Alert
          variant="info"
          show={this.state.info ? this.state.info.isShowing : false}
        >
          {this.state.info ? this.state.info.message : ''}
        </Alert>
      </div>
    )
  }

  private onVerifyClicked(event: React.ChangeEvent<any>) {
    event.preventDefault()

    this.setState({
      loading: { isShowing: true }
    })

    Auth.confirmSignUp(this.state.username, this.state.code)
      .then(_data => {
        this.setState({
          loading: undefined
        })
        store.dispatch(push({
          pathname: NetworkConstants.URL_LOGIN,
          state: {
            alert: {
              isShowing: true,
              message: AlertConstants.CODE_VERIFICATION_SUCCESS,
              variant: AlertVariants.SUCCESS
            }
          }
        }))
      })
      .catch(err => {
        this.setState({
          loading: undefined,
          error: {
            isShowing: true,
            message: err.message ? err.message : AlertConstants.GENERIC_ERROR
          },
          info: undefined
        })
      })
  }

  private onResendCodeClicked(event: React.ChangeEvent<any>) {
    event.preventDefault()

    this.setState({
      loading: { isShowing: true }
    })

    Auth.resendSignUp(this.state.username)
      .then(_ => {
        this.setState({
          loading: undefined,
          info: {
            isShowing: true,
            message: AlertConstants.CODE_VERIFICATION_RESEND
          },
          error: undefined
        })
      })
      .catch(err => {
        this.setState({
          loading: undefined,
          error: {
            isShowing: true,
            message: err.message ? err.message : AlertConstants.GENERIC_ERROR
          },
          info: undefined
        })
      })
  }

  private handleUsernameInputChange(input: string) {
    this.setState({
      username: input,
      error: undefined,
      info: undefined
    })
  }

  private handleCodeInputChange(input: string) {
    this.setState({
      code: input,
      error: undefined,
      info: undefined
    })
  }
}

export default CodeVerification
