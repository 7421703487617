import React from 'react'
import { Redirect, Route } from 'react-router'
import { isAuthenticated } from '../../utils/AuthenticationUtil'

export const SecuredRoute = ({ component: C, path, redirect, ...rest }: any) => {
  const routeComponent = (props: any) => {
    return isAuthenticated() ?
      <C {...props} {...rest} />
      :
      <Redirect to={{ pathname: redirect }} />

  }

  return <Route path={path} render={routeComponent} />
}
