import { CognitoUser } from '@aws-amplify/auth'
import { Auth } from 'aws-amplify'
import { push, replace } from 'connected-react-router'
import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import store from '../../../stores/store'
import {
  getAuthStateFromCognitoUser,
  isAuthenticated,
  setAuthState
} from '../../../utils/AuthenticationUtil'
import { AWS_COGNITO_CHALLENGE_NEW_PASSWORD } from '../../../utils/AwsConstants'
import { NetworkConstants } from '../../../utils/NetworkConstants'
import FormInput from '../../atoms/FormInput'
import LoadingOverlay from '../../molecules/LoadingOverlay'
import { AlertState } from '../../../types/messageState'
import { AlertVariants } from '../../../utils/AlertUtils'
import { AlertConstants } from '../../../utils/AlertConstants'
import Helmet from 'react-helmet'

interface LoginProps {
  location?: any
}

interface LoginState {
  user: {
    username: string
    password: string
  }
  cognitoUser?: CognitoUser
  alert?: AlertState
  loading?: {
    isShowing: boolean
    message?: string
  }
  showNewPasswordChallenge: boolean
  prevLocation?: {
    url: string,
    data: any
  }
}

const defaultState: LoginState = {
  user: {
    username: '',
    password: ''
  },
  loading: undefined,
  showNewPasswordChallenge: false
}

class Login extends React.Component<LoginProps, LoginState> {
  public constructor(props: any) {
    super(props)

    this.state = defaultState

    this.handleUsernameInputChange = this.handleUsernameInputChange.bind(this)
    this.handlePasswordInputChange = this.handlePasswordInputChange.bind(this)

    this.onLoginClicked = this.onLoginClicked.bind(this)
    this.onNewPasswordClicked = this.onNewPasswordClicked.bind(this)

    if (props.location && props.location.state) {
      this.state = {
        ...this.state,
        alert: props.location.state.alert,
        prevLocation: props.location.state.prevLocation
      }
    }
  }

  public render() {
    if (isAuthenticated()) {
      this.loginRedirect()
    }

    return (
      <div className="login">
        <Helmet>
          <title>AHEAD - Login</title>
        </Helmet>
        <LoadingOverlay
          isShowing={this.state.loading ? this.state.loading.isShowing : false}
          loadingText={this.state.loading ? this.state.loading.message : ''}
        />

        {this.getAlerts()}

        <h1>
          {this.state.showNewPasswordChallenge ? 'New password' : 'Login'}
        </h1>

        {this.state.showNewPasswordChallenge
          ? this.showNewPassword()
          : this.showLoginForm()}
      </div>
    )
  }

  private getAlerts(): JSX.Element | string {
    const { alert } = this.state

    if (alert) {
      return (
        <div className="login-alerts-container">
          <Alert
            variant={alert.variant ? alert.variant : 'info'}
            show={alert.isShowing}
          >
            {alert.message}
          </Alert>
        </div>
      )
    }

    return ''
  }

  private showNewPassword(): JSX.Element {
    return (
      <Form
        data-testid="login-new-password-form"
        className="d-flex flex-column mt-3"
        onSubmit={this.onNewPasswordClicked}
      >
        <FormGroup>
          <FormInput
            type="password"
            placeholder="New password"
            callback={this.handlePasswordInputChange}
            value={this.state.user.password}
          />
        </FormGroup>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    )
  }

  private showLoginForm(): JSX.Element {
    return (
      <Form className="d-flex flex-column mt-3" onSubmit={this.onLoginClicked}>
        <FormGroup>
          <FormInput
            type="text"
            placeholder="Username or email"
            callback={this.handleUsernameInputChange}
            value={this.state.user.username}
          />
        </FormGroup>

        <FormGroup>
          <FormInput
            type="password"
            placeholder="Password"
            callback={this.handlePasswordInputChange}
            value={this.state.user.password}
          />
        </FormGroup>

        <Button variant="primary" type="submit">
          Submit
        </Button>
        <div className="login-links-container mt-4">
          <Row className="login-links">
            <Link to={NetworkConstants.URL_FORGOT_PASSWORD}>
              Forgot password
            </Link>
          </Row>
          <Row className="login-links mt-2">
            <Link to={NetworkConstants.URL_SIGN_UP}>Sign up</Link>
          </Row>
        </div>
      </Form>
    )
  }

  private handleUsernameInputChange(input: string): void {
    this.setState(prevState => ({
      user: {
        username: input,
        password: prevState.user.password
      },
      alert: undefined
    }))
  }

  private handlePasswordInputChange(input: string): void {
    this.setState(prevState => ({
      user: {
        username: prevState.user.username,
        password: input
      },
      alert: undefined
    }))
  }

  private onLoginClicked(event: React.ChangeEvent<any>): void {
    event.preventDefault()

    this.setState({
      loading: { isShowing: true }
    })

    Auth.signIn(this.state.user.username, this.state.user.password)
      .then(cognitoUser => {
        if (cognitoUser.challengeName === AWS_COGNITO_CHALLENGE_NEW_PASSWORD) {
          this.setState({
            user: {
              username: this.state.user.username,
              password: ''
            },
            cognitoUser: cognitoUser,
            loading: { isShowing: false },
            showNewPasswordChallenge: true
          })
        } else {
          const authState = getAuthStateFromCognitoUser(cognitoUser)
          setAuthState(authState)
          this.loginRedirect()
        }
      })
      .catch(err => {
        if (err.code === 'UserNotConfirmedException') {
          store.dispatch(
            push({
              pathname: NetworkConstants.URL_CODE_VERIFICATION,
              state: {
                username: this.state.user.username,
                errorMessage: `${
                  this.state.user.username
                  } has not been verified yet.`
              }
            })
          )
        } else {
          this.setState({
            loading: { isShowing: false },
            alert: {
              isShowing: true,
              message: err.message ? err.message : AlertConstants.GENERIC_ERROR,
              variant: AlertVariants.DANGER
            }
          })
        }
      })
  }

  private onNewPasswordClicked(_event: React.ChangeEvent<any>): void {
    this.setState({
      loading: { isShowing: true, message: 'Updating password...' }
    })

    Auth.completeNewPassword(
      this.state.cognitoUser,
      this.state.user.password,
      null
    )
      .then(_result => {
        this.setState({
          loading: { isShowing: false },
          showNewPasswordChallenge: false
        })
      })
      .catch(error => {
        this.setState({
          loading: { isShowing: false },
          alert: {
            isShowing: true,
            message: error.message,
            variant: AlertVariants.DANGER
          }
        })
      })
  }

  private loginRedirect = (): void => {
    if (this.state.prevLocation) {
      store.dispatch(replace({
        pathname: this.state.prevLocation.url,
        state: {
          ...this.state.prevLocation.data
        }
      }))
    } else {
      store.dispatch(push(NetworkConstants.URL_HOME))
    }
  }
}

export default Login
