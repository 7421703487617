import React from 'react'
import { Modal } from 'react-bootstrap'
import ModalCloseIcon from '../atoms/ModalCloseIcon'
import { DatasetDataImage } from '../../types/dataverse/dataset'

interface ImageModalProps {
  show: boolean
  originalImage?: DatasetDataImage
  closeButtonCallback: () => void
}

const ImageModal = ({ show, originalImage, closeButtonCallback }: ImageModalProps) => {
  const renderImage = (): JSX.Element => {
    if (originalImage && originalImage.dataImage) {
      return (
        <img
          className={'ahead-modal-content-image img-responsive'}
          src={originalImage.dataImage.src}
          alt={`original file ${originalImage.filename}`}
        />
      )
    } else {
      return <span>Original image not available for this file</span>
    }
  }

  return (
    <Modal
      className={'ahead-modal'}
      size="xl"
      centered
      show={show}
      onHide={closeButtonCallback}>
      <Modal.Body>
        <ModalCloseIcon
          onCloseIconClicked={closeButtonCallback}
          altText={'close modal icon'} />
        <div className={'ahead-modal-content'}>
          {renderImage()}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ImageModal