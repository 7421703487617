export class AssetsConstants {
  public static LOGO_URL = '/assets/AHEAD_logo.svg'
  public static DIVIDER_LINE_URL = '/assets/divider_line_icon.svg'
  public static SEARCH_ICON_URL = '/assets/search_icon.svg'
  public static CHECKBOX_ON_ICON = '/assets/checkbox_on_icon.svg'
  public static CHECKBOX_OFF_ICON = '/assets/checkbox_off_icon.svg'
  public static EDIT_ICON_BLACK_URL = '/assets/edit_icon_black.svg'
  public static EDIT_ICON_GREEN_URL = '/assets/edit_icon_green.svg'
  public static DOWNLOAD_ICON_BLACK_URL = '/assets/download_icon_black.svg'
  public static DOWNLOAD_ICON_GREEN_URL = '/assets/download_icon_green.svg'
  public static OPEN_ICON_URL = '/assets/open_icon.svg'
  public static BACK_ICON_URL = '/assets/green_arrow_back_icon.svg'
  public static CROSS_ICON_URL = '/assets/cross_icon.svg'
  public static GREEN_CROSS_ICON_URL = '/assets/green_cross_icon.svg'
  public static DATA_CURATOR_IMAGE_PLACEHOLDER = '/assets/data_curator_image_placeholder.png'
  public static TABS_DIVIDER_ICON_URL = '/assets/ahead-tabs-divider-icon.svg'
  public static TABS_DIVIDER_ICON_2_URL = '/assets/ahead-tabs-divider-icon-2.svg'
}
