import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PasswordActions } from '../../models/PasswordActions'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { SecuredRoute } from '../atoms/SecuredRoute'
import Footer from '../organisms/Footer'
import Header from '../organisms/Header'
import About from './About'
import Home from './Home'
import NotFound from './NotFound'
import CodeVerification from './user/CodeVerification'
import Login from './user/Login'
import Password from './user/Password'
import Signup from './user/Signup'
import Search from './Search'
import Dataset from './Dataset'
import DataCuratorTool from './DataCuratorTool'
import Helmet from 'react-helmet'

class Main extends React.Component {
  public render() {
    return (
      <div className="main">
        <Helmet>
          <meta charSet="utf-8" />
          <title>AHEAD - Home</title>
          <meta name="description" content="AHEAD - A project to democratize economic historical data" />
        </Helmet>
        <Header />
        <Switch>
          <Route exact path={NetworkConstants.URL_HOME} component={Home} />
          <Route path={NetworkConstants.URL_ABOUT} component={About} />
          <Route path={NetworkConstants.URL_SEARCH} component={Search} />
          <Route
            path={NetworkConstants.ROUTE_DATASET}
            render={props => <Dataset {...props} match={props.match} />}
          />
          <SecuredRoute
            path={NetworkConstants.URL_DATA_CURATOR_TOOL} component={DataCuratorTool}
            redirect={NetworkConstants.URL_HOME} />
          <Route path={NetworkConstants.URL_LOGIN} component={Login} />
          <Route path={NetworkConstants.URL_SIGN_UP} component={Signup} />
          <Route
            path={NetworkConstants.URL_CODE_VERIFICATION}
            render={props => <CodeVerification {...props} />}
          />
          <Route
            path={NetworkConstants.URL_FORGOT_PASSWORD}
            render={props =>
              <Password
                {...props}
                passwordAction={PasswordActions.FORGOT_PASSWORD}
              />
            }
          />
          <SecuredRoute
            path={NetworkConstants.URL_CHANGE_PASSWORD}
            component={Password}
            passwordAction={PasswordActions.CHANGE_PASSWORD}
            redirect={NetworkConstants.URL_LOGIN}
          />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    )
  }
}

export default Main
