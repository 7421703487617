import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { InputGroup } from 'react-bootstrap'
import { AssetsConstants } from '../../utils/AssetsConstants'

interface SearchInputProps {
  placeholderText?: string;
  onSubmitCallback?: any;
  defaultInput?: string;
}

const SearchInput = ({ placeholderText, onSubmitCallback, defaultInput }: SearchInputProps) => {
  const [query, setQuery] = useState(defaultInput ? defaultInput : '')

  return (
    <Form
      inline
      className="search-form"
      onSubmit={(event: React.SyntheticEvent) => {
        event.preventDefault()
        onSubmitCallback(query)
      }}
    >
      <InputGroup className={'search-form-input-group'}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <img
              className={'search-form-input-icon'}
              src={AssetsConstants.SEARCH_ICON_URL}
              alt={'Search icon'}
            />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          aria-label={'Search input'}
          type="text"
          placeholder={placeholderText ? placeholderText : 'Search dataset'}
          value={query}
          className="search-form-input-control"
          onChange={(event: any) => {
            setQuery(event.target.value)
          }}
        />
      </InputGroup>
    </Form>
  )
}

export default SearchInput
