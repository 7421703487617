import React from 'react'
import Modal from 'react-bootstrap/Modal'
import LoadingSpinner from '../atoms/LoadingSpinner'

interface LoadingOverlayProps {
  isShowing: boolean
  loadingText?: string
}

class LoadingOverlay extends React.Component<LoadingOverlayProps> {
  public render() {
    return (
      <div className="loading-overlay" data-testid="loading-overlay">
        <Modal
          className={'loading-overlay-modal'}
          show={this.props.isShowing}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation
          backdrop={'static'}
        >
          <Modal.Body className="p-4">
            <LoadingSpinner text={this.props.loadingText} />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default LoadingOverlay
