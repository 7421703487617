import { EndpointsHelper } from './endpointsHelper'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { FILEBLOB_BASE64STRING_INDEX } from '../utils/Constants'
import { DatasetInformation, DatasetInformationFile, UserChanges } from '../types/dataverse/dataset'
import { Base64ImageInformation } from '../types/images'
import { TableChange } from '../types/aheadTable'
import { getJwtToken } from '../utils/AuthenticationUtil'
import { DatasetUtil } from '../utils/DatasetUtil'
import HttpStatus from 'http-status-codes'

export class DataverseService {
  public static getLatestDatasets(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(EndpointsHelper.getLatestDataverseDatasetsEndpoint())
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  public static getDatasetInformation(datasetId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(EndpointsHelper.getDatasetInformation(datasetId))
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  public static search(query: string, startPosition: number, sortBy = '', sortOrder = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      const queryParameters = `?query=${query}&type=dataset&paginationStartPosition=${startPosition}&sortBy=${sortBy}&sortOrder=${sortOrder}`
      axios
        .get(`${EndpointsHelper.getSearchEndpoint()}${queryParameters}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  public static getFile(file: DatasetInformationFile): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(EndpointsHelper.getDownloadFileEndpoint(file))
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  public static getDatasetVersions(datasetId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(EndpointsHelper.getDatasetVersions(datasetId))
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  public static submitNewDatasetVersion(datasetInformation: DatasetInformation, fileInformation: DatasetInformationFile, fileBlob: Blob, changePercentage: number): Promise<any> {
    return this.fileBlobToBase64(fileBlob).then(async (result: any) => {
      const data: Base64ImageInformation = {
        'base64File': result.target.result.split(',')[FILEBLOB_BASE64STRING_INDEX],
        'filename': DatasetUtil.getOriginalFilename(fileInformation)
      }
      const options: AxiosRequestConfig = {
        headers: {
          Authorization: await getJwtToken(),
          'Content-type': 'application/json',
          'root-datafile-id': `${fileInformation.rootDataFileId}`,
          'old-major-version': `${datasetInformation.versionNumber}`,
          'old-minor-version': `${datasetInformation.versionMinorNumber}`,
          'change-percentage': `${changePercentage}`
        }
      }

      return new Promise((resolve, reject) => {
        axios
          .post(EndpointsHelper.submitNewDatasetVersion(datasetInformation.id, fileInformation.fileId), JSON.stringify(data), options)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    })
  }

  public static getImagesInformation(datasetId: number, filename: string): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      axios
        .get(EndpointsHelper.getImagesInformation(datasetId, filename))
        .then(response => {
          if (response.status !== HttpStatus.OK) {
            response.data = []
            resolve(response)
          } else {
            resolve(response)
          }
        })
        .catch(error => reject(error))
    })
  }

  public static async addUserDatasetChanges(dataset: DatasetInformation, file: DatasetInformationFile, tableChanges: TableChange[]): Promise<AxiosResponse> {
    const data: UserChanges = {
      rootDataFileId: file.rootDataFileId,
      versionNumber: dataset.versionNumber,
      versionMinorNumber: dataset.versionMinorNumber,
      versionState: dataset.versionState,
      changes: tableChanges
    }
    const options: AxiosRequestConfig = {
      headers: {
        Authorization: await getJwtToken(),
        'Content-type': 'application/json'
      }
    }

    return axios.post(EndpointsHelper.userDatasetChanges(dataset.id), JSON.stringify(data), options)
  }

  public static async getUserDatasetChanges(datasetId: number, rootDataFileId: number): Promise<AxiosResponse> {
    const options: AxiosRequestConfig = {
      headers: {
        Authorization: await getJwtToken(),
        'Content-type': 'application/json'
      }
    }

    return axios.get(EndpointsHelper.userDatasetChanges(datasetId, rootDataFileId), options)
  }

  private static fileBlobToBase64(fileBlob: Blob): Promise<any> {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onload = resolve
      reader.readAsDataURL(fileBlob)
    })
  }
}