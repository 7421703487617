import { DatasetSearchInformation } from './dataset'

export interface SearchOptions {
  query: string;
  startPosition?: number;
  type?: SearchType;
  sortAttribute?: SearchSortAttribute;
  order?: SearchOrder;
  itemsPerPage?: number;
  showEntityIds?: boolean;
  showRelevance?: boolean;
}
export enum SearchType {
  DATAVERSE = 'dataverse',
  DATASET = 'dataset',
  FILE = 'file'
}
export enum SearchSortAttribute {
  NAME = 'name',
  DATE = 'date',
  DEFAULT = ''
}
export enum SearchOrder {
  ASC = 'asc',
  DESC = 'desc',
  DEFAULT = ''
}

export interface SearchResponse {
  query: string,
  totalCount: number,
  startPosition: number,
  itemsCount: number,
  items: DatasetSearchInformation[]
}
