import React from 'react'
import { Row } from 'react-bootstrap'
import { Link } from '../../types/link'
import LinkListItem from '../atoms/LinkListItem'

interface LinksListProps {
  title: string,
  links: Link[]
}

class LinksList extends React.Component<LinksListProps> {
  public render() {
    return (
      <div className={'links-list'}>
        <Row className={'links-list-title'}>
          {this.props.title}
        </Row>
        {this.getLinks(this.props.links)}
      </div>
    )
  }

  private getLinks(links: Link[]): JSX.Element | string {
    if (links && links.length > 0) {
      const result: JSX.Element[] = []
      links.forEach((link, index) => {
        result.push(<LinkListItem key={index} url={link.url} text={link.text} newWindow={link.newWindow} />)
      })
      return (
        <div className={'links-list-items'}>
          {result}
        </div>
      )
    }

    return ''
  }
}

export default LinksList