import React from 'react'
import { PasswordActions } from '../../../models/PasswordActions'
import { Redirect } from 'react-router'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import FormGroup from 'react-bootstrap/FormGroup'
import FormInput from '../../atoms/FormInput'
import Button from 'react-bootstrap/Button'
import { Auth } from 'aws-amplify'
import LoadingOverlay from '../../molecules/LoadingOverlay'
import store from '../../../stores/store'
import { push } from 'connected-react-router'
import { NetworkConstants } from '../../../utils/NetworkConstants'
import { AlertState } from '../../../types/messageState'
import { AlertVariants } from '../../../utils/AlertUtils'
import { AlertConstants } from '../../../utils/AlertConstants'
import Helmet from 'react-helmet'

interface PasswordState {
  username: string
  oldPassword: string
  newPassword: string
  code: string
  loading?: { isShowing: boolean; message?: string }
  alert?: AlertState
  showSubmitNewPassword: boolean
}

interface PasswordProps {
  passwordAction: PasswordActions
}

const defaultState: PasswordState = {
  username: '',
  oldPassword: '',
  newPassword: '',
  code: '',
  showSubmitNewPassword: false
}

class Password extends React.Component<PasswordProps, PasswordState> {
  public constructor(props: any) {
    super(props)

    this.state = defaultState

    this.onResetPasswordClicked = this.onResetPasswordClicked.bind(this)
    this.onSubmitNewPasswordClicked = this.onSubmitNewPasswordClicked.bind(this)
    this.onChangePasswordClicked = this.onChangePasswordClicked.bind(this)

    this.handleUsernameInputChange = this.handleUsernameInputChange.bind(this)
    this.handleCodeInputChange = this.handleCodeInputChange.bind(this)
    this.handleNewPasswordInputChange = this.handleNewPasswordInputChange.bind(
      this
    )
    this.handleOldPasswordInputChange = this.handleOldPasswordInputChange.bind(
      this
    )
  }

  public render() {
    let component: JSX.Element
    let pageTitle: string

    if (this.state.showSubmitNewPassword) {
      pageTitle = 'New password'
      component = this.getSubmitNewPassword()
    } else {
      switch (this.props.passwordAction) {
        case PasswordActions.FORGOT_PASSWORD:
          pageTitle = 'Forgot password'
          component = this.getForgotPassword()
          break
        case PasswordActions.CHANGE_PASSWORD:
          pageTitle = 'Change password'
          component = this.getChangePassword()
          break
        default:
          return <Redirect to={NetworkConstants.URL_HOME} />
      }
    }

    return (
      <div className="password">
        <Helmet>
          <title>{`AHEAD - ${pageTitle}`}</title>
        </Helmet>
        <LoadingOverlay
          isShowing={this.state.loading ? this.state.loading.isShowing : false}
          loadingText={this.state.loading ? this.state.loading.message : ''}
        />

        {this.getAlerts()}

        <h1>{this.getTitleName()}</h1>
        {component}
      </div>
    )
  }

  private getAlerts(): JSX.Element | string {
    const { alert } = this.state

    if (alert) {
      return (
        <div className="password-alerts-container">
          <Alert
            variant={alert.variant ? alert.variant : 'info'}
            show={alert.isShowing}
          >
            {alert.message}
          </Alert>
        </div>
      )
    }

    return ''
  }

  private getForgotPassword(): JSX.Element {
    return (
      <Form
        className="d-flex flex-column mt-3"
        onSubmit={this.onResetPasswordClicked}
      >
        <FormGroup>
          <FormInput
            type="text"
            placeholder="Username"
            callback={this.handleUsernameInputChange}
            value={this.state.username}
            aria-label={'Username input'}
          />
        </FormGroup>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    )
  }

  private getSubmitNewPassword(): JSX.Element {
    return (
      <Form
        className="d-flex flex-column mt-3"
        onSubmit={this.onSubmitNewPasswordClicked}
        data-testid="submit-new-password-form"
      >
        <FormGroup>
          <FormInput
            type="text"
            placeholder="Username"
            callback={this.handleUsernameInputChange}
            value={this.state.username}
            aria-label={'Username input'}
          />
        </FormGroup>

        <FormGroup>
          <FormInput
            type="text"
            placeholder="Verification code"
            callback={this.handleCodeInputChange}
            value={this.state.code}
            aria-label={'Verification code input'}
          />
        </FormGroup>

        <FormGroup>
          <FormInput
            type="password"
            placeholder="New password"
            callback={this.handleNewPasswordInputChange}
            value={this.state.newPassword}
            aria-label={'New password input'}
          />
        </FormGroup>

        <Button variant="primary" type="submit" data-testid='submit-new-password-button'>
          Submit
        </Button>
      </Form>
    )
  }

  private getChangePassword(): JSX.Element {
    return (
      <Form
        className="d-flex flex-column mt-3"
        onSubmit={this.onChangePasswordClicked}
      >
        <FormGroup>
          <FormInput
            type="password"
            placeholder="Old password"
            callback={this.handleOldPasswordInputChange}
            value={this.state.oldPassword}
            aria-label={'Old password input'}
          />
        </FormGroup>

        <FormGroup>
          <FormInput
            type="password"
            placeholder="New password"
            callback={this.handleNewPasswordInputChange}
            value={this.state.newPassword}
            aria-label={'New password input'}
          />
        </FormGroup>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    )
  }

  private onResetPasswordClicked(event: React.ChangeEvent<any>) {
    event.preventDefault()

    this.setState({ loading: { isShowing: true } })

    Auth.forgotPassword(this.state.username)
      .then(data => {
        this.setState({
          loading: undefined,
          alert: {
            isShowing: true,
            message: `Your verification code has been sent to: ${
              data.CodeDeliveryDetails.Destination
              }`,
            variant: AlertVariants.INFO
          },
          showSubmitNewPassword: true
        })
      })
      .catch(err => {
        this.handleError(err)
      })
  }

  private onSubmitNewPasswordClicked(event: React.ChangeEvent<any>) {
    event.preventDefault()

    this.setState({ loading: { isShowing: true } })

    Auth.forgotPasswordSubmit(
      this.state.username,
      this.state.code,
      this.state.newPassword
    )
      .then(_ => {
        store.dispatch(push({
          pathname: NetworkConstants.URL_LOGIN,
          state: {
            alert: {
              isShowing: true,
              message: AlertConstants.PASSWORD_UPDATE_SUCCESS,
              variant: AlertVariants.SUCCESS
            }
          }
        }))
      })
      .catch(err => {
        this.handleError(err)
      })
  }

  private onChangePasswordClicked(event: React.ChangeEvent<any>) {
    event.preventDefault()

    this.setState({ loading: { isShowing: true } })

    Auth.currentAuthenticatedUser()
      .then(cognitoUser => {
        Auth.changePassword(
          cognitoUser,
          this.state.oldPassword,
          this.state.newPassword
        )
          .then(() => {
            this.setState({
              loading: undefined,
              alert: {
                isShowing: true,
                message: AlertConstants.PASSWORD_CHANGE_SUCCESS,
                variant: AlertVariants.INFO
              },
              oldPassword: '',
              newPassword: ''
            })
          })
          .catch(err => {
            this.handleError(err)
          })
      })
      .catch(err => {
        this.handleError(err)
      })
  }

  private handleUsernameInputChange(input: string) {
    this.setState({
      username: input,
      alert: undefined
    })
  }

  private handleCodeInputChange(input: string) {
    this.setState({
      code: input,
      alert: undefined
    })
  }

  private handleNewPasswordInputChange(input: string) {
    this.setState({
      newPassword: input,
      alert: undefined
    })
  }

  private handleOldPasswordInputChange(input: string) {
    this.setState({
      oldPassword: input,
      alert: undefined
    })
  }

  private handleError(err: any) {
    this.setState({
      loading: undefined,
      alert: {
        isShowing: true,
        message: err.message ? err.message : AlertConstants.GENERIC_ERROR,
        variant: AlertVariants.DANGER
      }
    })
  }

  private getTitleName = (): string => {
    if (this.state.showSubmitNewPassword) {
      return 'New password'
    }

    if (this.props.passwordAction !== undefined) {
      if (this.props.passwordAction === PasswordActions.FORGOT_PASSWORD) {
        return 'Reset password'
      }

      if (this.props.passwordAction === PasswordActions.CHANGE_PASSWORD) {
        return 'Change password'
      }
    }

    return ''
  }
}

export default Password
