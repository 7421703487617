import Papa, { ParseResult } from 'papaparse'
import { CsvData, ReactTableInformation, TableChange } from '../types/aheadTable'
import {
  AHEAD_TABLE_COLUMN_SPACING,
  AHEAD_TABLE_COLUMN_EXTRA_SPACING,
  TABLE_DEFAULT_PAGE_SIZE,
  AHEAD_TABLE_ONE_COLUMN
} from './Constants'
import { BoundingBoxInformation, SelectedCell } from '../types/canvas'
import { CanvasUtil } from './CanvasUtil'

export const DEFAULT_HEADERS = ['cls', 'text']
export const HEADER_CLS = 'cls'
export const HEADER_COL = 'col'
export const HEADER_ROW = 'row'
export const HEADER_ROW_BOUNDING_BOX = 'row_bbox'

const HEADER_SYMBOL_ID = 'symbol_id'

export default class AheadTableUtil {
  public static getCsvData(response: string): CsvData {
    let data: any[] = []
    let headers: any[] = []

    if (!response) {
      return { headers: [], data: data }
    }

    const result: ParseResult = Papa.parse(response, { header: true, skipEmptyLines: true, delimiter: ',' })

    if (result.data) {
      data = result.data
    }

    if (result.meta) {
      headers = result.meta.fields
    }

    return { headers: headers, data: data }
  }

  public static getFilteredCsvData(response: string): CsvData {
    const result = this.getCsvData(response)

    return {
      headers: result.headers,
      data: result.data.filter(row => row[HEADER_SYMBOL_ID] === '0')
    }
  }

  public static convertToCSV(tableData: any[]): string {
    if (tableData.length === 0) {
      return ''
    }

    return Papa.unparse(tableData, { header: true, delimiter: ',', skipEmptyLines: true })
  }

  public static shouldDisplayHeader(header: string): boolean {
    return DEFAULT_HEADERS.includes(header)
  }

  public static applyCsvChanges(originalCsv: string, tableChanges: TableChange[]): any[] {
    const data = [...AheadTableUtil.getCsvData(originalCsv).data]

    tableChanges.forEach(change => {
      data.map(row => {
        if (row.row === change.row && row.col === change.col && row[change.header] !== undefined) {
          return row[change.header] = change.value
        } else {
          return row
        }
      })
    })

    return data
  }

  public static getColumnWidth(data: any[], header: string): number {
    const columnLength = Math.max(...data.map(row => {
      return row[header] ? row[header].toString().length : 0
    }), header.length)

    return columnLength * AHEAD_TABLE_COLUMN_SPACING + AHEAD_TABLE_COLUMN_EXTRA_SPACING
  }

  public static getDataFromTableChanges(originalCsv: string, tableChanges: TableChange[]): any[] {
    const modifiedUnfilteredData = AheadTableUtil.applyCsvChanges(originalCsv, tableChanges)
    const modifiedCsv = AheadTableUtil.convertToCSV(modifiedUnfilteredData)
    return AheadTableUtil.getFilteredCsvData(modifiedCsv).data
  }

  public static isModifiedCell(cellInfo: any, originalData: any[], modifiedData: any[]) {
    if (originalData.length === 0 || originalData.length !== modifiedData.length) {
      return false
    }

    return originalData[cellInfo.index][cellInfo.column.id] !== modifiedData[cellInfo.index][cellInfo.column.id]
  }

  // This method relies on `tableData.data` to have a `col` and `row_bbox` property
  public static getSelectedCellFromTableIndex(tableData: ReactTableInformation, index: number, hasBoundingBox = true): SelectedCell {
    const rawBoundingBoxText = tableData.data[index][HEADER_ROW_BOUNDING_BOX]
    const boundingBoxCoordinates: BoundingBoxInformation = CanvasUtil.getBoundingBoxCoordinates(rawBoundingBoxText)
    const height = boundingBoxCoordinates.maxY - boundingBoxCoordinates.minY
    const col = tableData.data[index][HEADER_COL]

    return {
      x: boundingBoxCoordinates.minX,
      y: boundingBoxCoordinates.minY,
      height: height,
      col: col,
      hasBoundingBox: hasBoundingBox
    }
  }

  static filterTableChanges(originalCsv: string, tableChanges: TableChange[]) {
    const data = [...AheadTableUtil.getCsvData(originalCsv).data]

    return tableChanges.filter((tableChange: TableChange) => {
      let shouldKeepChange = false
      data.forEach(row => {
        if (row.row === tableChange.row && row.col === tableChange.col && row[tableChange.header] !== tableChange.value) {
          shouldKeepChange = true
        }
      })

      return shouldKeepChange
    })
  }

  // This method relies on `tableData.data` to have a `col` property
  public static getColumnNumbersText(tableData: ReactTableInformation, currentIndex: number): string {
    if (tableData.data.length > 0) {
      const currentPageIndex = TABLE_DEFAULT_PAGE_SIZE * currentIndex
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const currentColumn = parseInt(tableData.data[currentPageIndex][HEADER_COL]) + 1
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const totalColumns = parseInt(tableData.data[tableData.data.length - 1][HEADER_COL]) + 1

      if (totalColumns === AHEAD_TABLE_ONE_COLUMN) {
        return `${currentColumn} of ${totalColumns} Column`
      }

      return `${currentColumn} of ${totalColumns} Columns`
    }

    return 'No columns available'
  }
}