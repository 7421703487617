import React from 'react'
import {
  DatasetInformation,
  DatasetInformationDescription,
  DatasetInformationFile
} from '../../types/dataverse/dataset'
import DatasetHeader from '../molecules/DatasetHeader'
import DatasetDescription from '../molecules/DatasetDescription'
import DatasetMetadata from '../molecules/DatasetMetadata'
import DatasetFileList from '../organisms/DatasetFileList'
import { Button, Col, Row } from 'react-bootstrap'
import { Redirect } from 'react-router'
import { AssetsConstants } from '../../utils/AssetsConstants'
import store from '../../stores/store'
import DownloadModal from '../molecules/DownloadModal'
import { goBack, push } from 'connected-react-router'
import { DatasetUtil } from '../../utils/DatasetUtil'
import UnauthenticatedEditModal from '../molecules/UnauthenticatedEditModal'
import { DataverseService } from '../../api/dataverseService'
import { LoadingState } from '../../types/loadingState'
import LoadingOverlay from '../molecules/LoadingOverlay'
import { NetworkConstants } from '../../utils/NetworkConstants'
import Helmet from 'react-helmet'

interface DatasetProps {
  match?: any,
  location?: any
}

interface DatasetState {
  datasetId: number
  datasetInformation: DatasetInformation
  showDownloadModal: boolean
  showUnauthenticatedEditModal: boolean
  selectedFile?: DatasetInformationFile
  latestDatasetInformation: DatasetInformation
  overlay: LoadingState
}

class Dataset extends React.Component<DatasetProps, DatasetState> {
  public constructor(props: any) {
    super(props)

    const datasetInformation: DatasetInformation = this.props.location.state ? this.props.location.state.datasetInformation : undefined

    this.state = {
      datasetId: this.props.match.params ? this.props.match.params.datasetId : undefined,
      datasetInformation: datasetInformation,
      showDownloadModal: false,
      showUnauthenticatedEditModal: false,
      latestDatasetInformation: datasetInformation,
      overlay: { isShowing: !datasetInformation }
    }
  }

  public render() {
    const { datasetInformation, latestDatasetInformation } = this.state
    const doi: string = latestDatasetInformation ? latestDatasetInformation.doi : ''
    const datasetTitle: string | undefined = datasetInformation ? datasetInformation.title : ''
    const datasetDescription: DatasetInformationDescription | undefined = datasetInformation ? datasetInformation.description : undefined
    const isEditableDatasetVersion: boolean = DatasetUtil.isEditableDatasetVersion(datasetInformation, latestDatasetInformation)

    if (!this.state.datasetId) {
      return <Redirect to={'/'} />
    }

    return <div className={'dataset'}>
      <Helmet>
        <title>{`AHEAD - Dataset - ${datasetTitle}`}</title>
      </Helmet>
      {datasetInformation ?
        <Row className={'dataset-columns-container'}>
          <Col md={1}>
            <Button variant={'link'} className={'dataset-back-button-container'}>
              <img
                className={'dataset-back-icon'}
                src={AssetsConstants.BACK_ICON_URL} alt={'go back icon'}
                onClick={() => this.onGoBackIconClicked()} />
            </Button>
          </Col>
          <Col md={11}>
            <DatasetHeader title={datasetTitle} />
            <DatasetDescription description={datasetDescription} />
            <DatasetMetadata
              datasetInformation={datasetInformation}
              latestDatasetInformation={latestDatasetInformation}
              onSelectDatasetVersionCallback={(datasetInformation: DatasetInformation) => this.selectDatasetVersion(datasetInformation)} />
            <Row className={'dataset-open-dataverse'}>
              <Button className={'dataset-open-dataverse-button'} variant="link">
                <img className={'dataset-open-dataverse-button-image'} src={AssetsConstants.OPEN_ICON_URL}
                  alt={'Open dataverse icon'} />
                <a className={'dataset-open-dataverse-button-link'}
                  href={DatasetUtil.getDataverseLink(doi, datasetInformation)}
                  target={'_blank'}
                  rel="noopener noreferrer">Open in Dataverse</a>
              </Button>
            </Row>
            <DatasetFileList
              datasetInformation={datasetInformation}
              isEditableDatasetVersion={isEditableDatasetVersion}
              onDownloadClickCallback={(file: DatasetInformationFile) => this.onDownloadFileClicked(file)}
              onUnauthenticatedEditClickCallback={(file: DatasetInformationFile) => this.onUnauthenticatedEditClicked(file)} />
            <DownloadModal
              show={this.state.showDownloadModal}
              closeButtonCallback={() => this.closeModals()}
              notLoggedInCallback={(file: DatasetInformationFile) => this.onUnauthenticatedEditClicked(file)}
              file={this.state.selectedFile}
              datasetInformation={datasetInformation}
              isEditableDatasetVersion={isEditableDatasetVersion} />
            <UnauthenticatedEditModal
              show={this.state.showUnauthenticatedEditModal}
              closeButtonCallback={() => this.closeModals()}
              prevLocation={
                {
                  url: NetworkConstants.URL_DATA_CURATOR_TOOL,
                  data: {
                    file: this.state.selectedFile,
                    datasetInformation: datasetInformation
                  }
                }} />
          </Col>
        </Row> : ''}
      <LoadingOverlay isShowing={this.state.overlay.isShowing} />
    </div>
  }

  public componentDidMount(): void {
    if (!this.state.datasetInformation) {
      DataverseService
        .getDatasetInformation(this.state.datasetId)
        .then((result: DatasetInformation) => {
          this.setState({
            ...this.state,
            overlay: { isShowing: false },
            datasetInformation: { ...result },
            latestDatasetInformation: { ...result }
          })
        })
        .catch(() => store.dispatch(push(NetworkConstants.URL_HOME)))
    }

    window.scrollTo(0, 0)
  }

  private selectDatasetVersion(datasetInformation: DatasetInformation): void {
    this.setState({
      datasetInformation: datasetInformation
    })
  }

  private onDownloadFileClicked(selectedFile: DatasetInformationFile): void {
    this.setState(
      {
        showDownloadModal: true,
        selectedFile: selectedFile
      }
    )
  }

  private onUnauthenticatedEditClicked(selectedFile?: DatasetInformationFile): void {
    this.setState({
      showUnauthenticatedEditModal: true,
      showDownloadModal: false,
      selectedFile: selectedFile
    })
  }

  private closeModals(): void {
    this.setState({
      showDownloadModal: false,
      showUnauthenticatedEditModal: false
    })
  }

  private onGoBackIconClicked(): void {
    store.dispatch(goBack())
  }
}

export default Dataset