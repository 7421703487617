import React, { useState } from 'react'
import { ListGroup, Row, Form } from 'react-bootstrap'
import { DatasetInformation, DatasetInformationFile } from '../../types/dataverse/dataset'
import DatasetFileListItem from '../molecules/DatasetFileListItem'
import { PAGINATION_DEFAULT_ITEMS_PER_PAGE, PAGINATION_FIRST_PAGE_INDEX } from '../../utils/Constants'
import PaginationBar from '../molecules/PaginationBar'
import { PaginationUtil } from '../../utils/PaginationUtil'
import { SearchFileInput } from '../atoms/SearchFileInput'

interface PaginationInformationState {
  currentPageIndex: number
  itemsPerPage: number
  startPosition: number
  totalCount: number
}

interface DatasetFileListProps {
  datasetInformation: DatasetInformation
  isEditableDatasetVersion: boolean
  onDownloadClickCallback?: (file: DatasetInformationFile) => void
  onUnauthenticatedEditClickCallback?: (file: DatasetInformationFile) => void
}

const DatasetFileList = (props: DatasetFileListProps) => {
  const [datasetInformation, setDatasetInformation] = useState<DatasetInformation>({
    ...props.datasetInformation
  })
  const [paginationState, setPaginationState] = useState<PaginationInformationState>({
    currentPageIndex: PAGINATION_FIRST_PAGE_INDEX,
    itemsPerPage: PAGINATION_DEFAULT_ITEMS_PER_PAGE,
    startPosition: 0,
    totalCount: datasetInformation && datasetInformation.files ? datasetInformation.files.length : 0
  })

  let datasetFiles: DatasetInformationFile[] = datasetInformation && datasetInformation.files ? datasetInformation.files : []
  datasetFiles = datasetFiles.slice(paginationState.startPosition, paginationState.startPosition + paginationState.itemsPerPage)

  const getListItems = (files: DatasetInformationFile[]): JSX.Element[] => {
    const items: JSX.Element[] = []

    files.forEach((file) => {
      items.push(
        <DatasetFileListItem
          file={file}
          key={file.fileId}
          datasetInformation={props.datasetInformation}
          isEditableDatasetVersion={props.isEditableDatasetVersion}
          onDownloadClickCallback={props.onDownloadClickCallback}
          onUnauthenticatedEditClickCallback={props.onUnauthenticatedEditClickCallback}
        />
      )
    })

    return items
  }

  const pageChangeCallback = (startPosition: number): void => {
    setPaginationState({
      ...paginationState,
      startPosition,
      currentPageIndex: startPosition / paginationState.itemsPerPage + PAGINATION_FIRST_PAGE_INDEX
    })
  }

  const handlePageJumpInput = (event: any): void => {
    event.preventDefault()
    const [input] = event.target.children
    const pageNumber = Number(input.value)
    const { totalCount, itemsPerPage } = paginationState
    const numberOfPages = Math.ceil(totalCount / itemsPerPage)

    if (pageNumber <= numberOfPages && pageNumber > 0) {
      setPaginationState({
        ...paginationState,
        startPosition: PaginationUtil.getSelectedPageStartingPosition(pageNumber, itemsPerPage),
        currentPageIndex: pageNumber
      })
    }
  }

  const renderPagination = (): JSX.Element | string => {
    const { currentPageIndex, itemsPerPage, startPosition, totalCount } = paginationState

    if (totalCount > itemsPerPage) {
      const numberOfPages = Math.ceil(totalCount / itemsPerPage)

      return (
        <Row className='dataset-files-pagination'>
          <PaginationBar
            currentPage={currentPageIndex}
            numberOfPages={numberOfPages}
            firstPageCallback={() => pageChangeCallback(PaginationUtil.getFirstPageStartingPosition())}
            previousPageCallback={() => pageChangeCallback(PaginationUtil.getPreviousPageStartingPosition(startPosition, itemsPerPage))}
            pageSelectedCallback={(pageIndex: number) => pageChangeCallback(PaginationUtil.getSelectedPageStartingPosition(pageIndex, itemsPerPage))}
            nextPageCallback={() => pageChangeCallback(PaginationUtil.getNextPageStartingPosition(startPosition, itemsPerPage))}
            lastPageCallback={() => pageChangeCallback(PaginationUtil.getLastPageStartingPosition(totalCount, itemsPerPage))}
          />
          <Form
            inline
            className="page-jump-form"
            onSubmit={(event: any) => handlePageJumpInput(event)}>
            <Form.Control className={'page-number-jump-input'} size={'sm'} type={'text'} placeholder={`Go to (${numberOfPages} pages)`} />
          </Form>
        </Row>
      )
    }
    return ''
  }

  const filterDatasetFiles = (value: string): void => {
    const filteredFiles = props.datasetInformation.files?.filter(x => x.filename.includes(value))

    setDatasetInformation({ ...datasetInformation, files: filteredFiles })
    setPaginationState({
      currentPageIndex: PAGINATION_FIRST_PAGE_INDEX,
      itemsPerPage: PAGINATION_DEFAULT_ITEMS_PER_PAGE,
      startPosition: 0,
      totalCount: filteredFiles ? filteredFiles.length : 0
    })
  }

  return <div className={'dataset-file-list'}>
    <Row className={'dataset-file-list-search'}>
      <SearchFileInput onChangeCallback={(value: string) => filterDatasetFiles(value)} />
    </Row>
    {datasetFiles && datasetFiles.length > 0 ?
      <div className={'dataset-file-list-items-container'}>
        <ListGroup className={'dataset-file-list-group'} variant={'flush'}>
          {getListItems(datasetFiles)}
        </ListGroup>
        {renderPagination()}
      </div>
      : ''}
  </div>

}

export default DatasetFileList