import React from 'react'
import { Modal } from 'react-bootstrap'
import ModalCloseIcon from '../atoms/ModalCloseIcon'
import ModalContent from '../atoms/ModalContent'

interface AheadModalProps {
  show: boolean
  children?: any
  closeButtonCallback: () => void
  title: string
  body: string
  signature?: string
}

class AheadModal extends React.Component<AheadModalProps> {
  public render() {
    const signature: string = this.props.signature ? this.props.signature : 'Thank you!'

    return (
      <Modal
        className={'ahead-modal'}
        size="lg"
        centered
        show={this.props.show}
        onHide={this.props.closeButtonCallback}>
        <Modal.Body>
          <ModalCloseIcon
            onCloseIconClicked={this.props.closeButtonCallback}
            altText={'close modal icon'} />
          <ModalContent
            title={this.props.title}
            bodyText={this.props.body}
            signature={signature} />
          {this.props.children}
        </Modal.Body>
      </Modal>
    )
  }
}

export default AheadModal