import React from 'react'
import { Button } from 'react-bootstrap'
import { AssetsConstants } from '../../utils/AssetsConstants'

interface ButtonWithTextAndCrossProps {
  buttonText: string,
  onClickCallback: any
}

const ButtonWithTextAndCross = ({ buttonText, onClickCallback }: ButtonWithTextAndCrossProps): JSX.Element => {
  const buttonClassName = `${buttonText.replace(/\s+/g, '-').toLowerCase()}-button`

  return (
    <Button
      variant={'light'}
      className={`${buttonClassName}`}
      onClick={(e: any) => {
        e.preventDefault()
        onClickCallback()
      }}>
      <img
        className={`${buttonClassName}-cross-icon`}
        src={AssetsConstants.CROSS_ICON_URL} alt={`${buttonText} cross icon`} />
      <span className={`${buttonClassName}-text`}>{buttonText}</span>
    </Button>
  )
}

export default ButtonWithTextAndCross