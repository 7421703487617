import React from 'react'
import { DatasetInformation, DatasetInformationFile } from '../../types/dataverse/dataset'
import { Button, Col, Row } from 'react-bootstrap'
import ButtonWithIcon from '../atoms/ButtonWithIcon'
import { AssetsConstants } from '../../utils/AssetsConstants'
import store from '../../stores/store'
import { push } from 'connected-react-router'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { DatasetUtil } from '../../utils/DatasetUtil'
import { isAuthenticated } from '../../utils/AuthenticationUtil'

interface DatasetFileListItemProps {
  file: DatasetInformationFile
  datasetInformation: DatasetInformation
  isEditableDatasetVersion: boolean
  onDownloadClickCallback?: any
  onUnauthenticatedEditClickCallback?: any
}

class DatasetFileListItem extends React.Component<DatasetFileListItemProps> {
  public render() {
    const file: DatasetInformationFile = this.props.file
    return <Row className={'dataset-file-list-item'}>
      <Col className={'dataset-file-list-item-filename'} sm={8}>
        {this.getFilename(file)}
      </Col>
      <Col className={'dataset-file-list-item-edit-button'} sm={2}>
        {this.getEditButton(file)}
      </Col>
      <Col className={'dataset-file-list-item-download-button'} sm={2}>
        <ButtonWithIcon
          onClickCallback={() => this.onDownloadClicked(file)}
          buttonText={'Download'}
          altText={'Download dataset icon'}
          icon={AssetsConstants.DOWNLOAD_ICON_BLACK_URL}
          hoverIcon={AssetsConstants.DOWNLOAD_ICON_GREEN_URL} />
      </Col>
    </Row>
  }

  private onDownloadClicked(file: DatasetInformationFile) {
    this.props.onDownloadClickCallback(file)
  }

  private getFilename(file: DatasetInformationFile): JSX.Element | string {
    if (!DatasetUtil.isCsvFile(file) || !this.props.isEditableDatasetVersion) {
      return <span>{DatasetUtil.getOriginalFilename(file)}</span>
    }

    return (
      <Button variant={'link'} className={'dataset-file-list-item-filename-link'}
        onClick={() => this.onEditClicked(file)}>
        <span>{DatasetUtil.getOriginalFilename(file)}</span>
      </Button>
    )
  }

  private getEditButton(file: DatasetInformationFile): JSX.Element | string {
    if (!DatasetUtil.isCsvFile(file) || !this.props.isEditableDatasetVersion) {
      return ''
    }

    return <ButtonWithIcon
      onClickCallback={() => this.onEditClicked(file)}
      buttonText={'Edit'}
      altText={'Edit dataset icon'}
      icon={AssetsConstants.EDIT_ICON_BLACK_URL}
      hoverIcon={AssetsConstants.EDIT_ICON_GREEN_URL} />
  }

  private onEditClicked(file: DatasetInformationFile): void {
    if (isAuthenticated()) {
      store.dispatch(push({
        pathname: NetworkConstants.URL_DATA_CURATOR_TOOL,
        state: {
          file,
          datasetInformation: this.props.datasetInformation
        }
      }))
    } else {
      this.props.onUnauthenticatedEditClickCallback(file)
    }
  }
}

export default DatasetFileListItem