const MONTHS_ABBREVIATION: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const MIN_MONTH_INDEX = 0
const MAX_MONTH_INDEX = 11

export class DatetimeUtil {


  public static getMonthYearDate(utcDateStr?: string): string {
    if (!utcDateStr) {
      return ''
    }

    const date = new Date(utcDateStr)

    return `${this.getMonthText(date.getMonth())} ${date.getFullYear()}`
  }

  public static getCurrentTimeStr(): string {
    return new Date().toISOString()
  }

  private static getMonthText(month: number) {
    if (month < MIN_MONTH_INDEX || month > MAX_MONTH_INDEX) {
      return ''
    }

    return MONTHS_ABBREVIATION[month]
  }
}