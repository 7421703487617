import React from 'react'

interface PostHeaderProps {
  title: string
  subtitle?: string
}

class PostHeader extends React.Component<PostHeaderProps> {

  public render() {
    return (
      <div className='post-header'>
        {this.getTitle()}
        {this.getSubtitle()}
      </div>
    )
  }

  private getTitle(): JSX.Element {
    return <div className={'post-header-title'}>
      <h1>{this.props.title}</h1>
    </div>
  }

  private getSubtitle(): JSX.Element | string {
    if (this.props.subtitle) {
      return <div className={'post-header-subtitle'}>
        {this.props.subtitle}
      </div>
    }

    return ''
  }
}

export default PostHeader