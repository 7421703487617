import React from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import { DatasetSearchInformation } from '../../types/dataverse/dataset'
import SearchListItem from '../molecules/SearchListItem'

interface SearchResultsListProps {
  datasetSearchInformationList: DatasetSearchInformation[]
}

class SearchList extends React.Component<SearchResultsListProps> {
  public render() {
    return (
      <div className={'search-list'}>
        <ListGroup variant={'flush'}>
          {this.getListItems(this.props.datasetSearchInformationList)}
        </ListGroup>
      </div>
    )
  }

  private getListItems(datasetSearchInformationList: DatasetSearchInformation[]): JSX.Element[] {
    const listItems: JSX.Element[] = []

    if (!datasetSearchInformationList) {
      return listItems
    }

    datasetSearchInformationList.forEach(datasetSearchInformation => {
      listItems.push(
        <ListGroupItem key={`group-item-${datasetSearchInformation.entity_id}`}>
          <SearchListItem key={datasetSearchInformation.entity_id} datasetSearchInformation={datasetSearchInformation} />
        </ListGroupItem>
      )
    })

    return listItems
  }
}

export default SearchList