import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import AheadTable from '../atoms/AheadTable'
import { DataverseService } from '../../api/dataverseService'
import EditorCloseModal from '../molecules/EditorCloseModal'
import AheadTableUtil, { HEADER_CLS, HEADER_COL, HEADER_ROW, HEADER_ROW_BOUNDING_BOX } from '../../utils/AheadTableUtil'
import ButtonWithTextAndCross from '../atoms/ButtonWithTextAndCross'
import {
  ALERT_MESSAGE_DURATION,
  DATA_CURATOR_TABLE_LHS_INDEX,
  DATA_CURATOR_TABLE_RHS_INDEX,
  PERCENTAGE,
  TABLE_CHANGE_DELETE_COUNT,
  TABLE_DEFAULT_PAGE_SIZE
} from '../../utils/Constants'
import { DatetimeUtil } from '../../utils/DatetimeUtil'
import { CsvData, ReactTableColumns, ReactTableInformation, TableChange } from '../../types/aheadTable'
import { CellInfo } from 'react-table'
import {
  DatasetDataImage,
  DatasetInformation,
  DatasetInformationFile,
  UserChanges
} from '../../types/dataverse/dataset'
import { Prompt } from 'react-router'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { Image, Layer, Rect, Stage } from 'react-konva'
import { SizeMe } from 'react-sizeme'
import store from '../../stores/store'
import { goBack, push } from 'connected-react-router'
import { NetworkConstants } from '../../utils/NetworkConstants'
import InformationModal from '../molecules/InformationModal'
import { HttpResponsesUtil } from '../../utils/HttpResponsesUtil'
import { DatasetUtil } from '../../utils/DatasetUtil'
import { ModalInformationBody } from '../../types/modal'
import { BoundingBoxInformation, SelectedCell } from '../../types/canvas'
import { CanvasUtil } from '../../utils/CanvasUtil'
import EditorDeleteChangesModal from '../molecules/EditorDeleteChangesModal'
import { ImageInformation, ImagesInformation } from '../../types/images'
import Alert from 'react-bootstrap/Alert'
import { AlertState } from '../../types/messageState'
import { isAuthenticated } from '../../utils/AuthenticationUtil'
import { AxiosError, AxiosResponse } from 'axios'
import { LoadingState } from '../../types/loadingState'
import LoadingOverlay from '../molecules/LoadingOverlay'
import { KeyDown } from '../../utils/KeydownConstants'
import HttpStatus from 'http-status-codes'
import { AssetsConstants } from '../../utils/AssetsConstants'
import { AlertVariants } from '../../utils/AlertUtils'
import EditorSubmitVersionModal from '../molecules/EditorSubmitVersionModal'
import ImageModal from '../molecules/ImageModal'
import { AlertConstants } from '../../utils/AlertConstants'
import Helmet from 'react-helmet'
import LoadingSpinner from '../atoms/LoadingSpinner'

interface DataCuratorToolProps {
  location: any
}

interface DataCuratorToolState {
  overlay: LoadingState,
  datasetInformation?: DatasetInformation
  file?: DatasetInformationFile
  originalCsv: string
  currentTablePageIndex: number
  original: ReactTableInformation
  modified: ReactTableInformation
  showCloseEditorModal: boolean
  showDeleteChangesDatasetModal: boolean
  showSubmitDatasetModal: boolean
  showSubmitVersionModal: boolean
  submitDatasetModalContent?: ModalInformationBody
  showUserChangesModal: boolean
  showImageModal: boolean
  userChangesModalContent?: ModalInformationBody
  tableChanges: TableChange[]
  dataImages: {
    columnImages: DatasetDataImage[]
    originalImage?: DatasetDataImage
  }
  imagesInformation: {
    images?: ImageInformation[]
    originalImage?: ImageInformation
    showRefreshButton: boolean
  }
  selectedCell?: SelectedCell
  alert?: AlertState
  isImagesTabActive: boolean
}

const defaultOriginalData = {
  columns: [],
  data: []
}

const defaultModifiedData = {
  columns: [],
  data: []
}

const defaultState: DataCuratorToolState = {
  overlay: {
    isShowing: false
  },
  originalCsv: '',
  currentTablePageIndex: 0,
  original: defaultOriginalData,
  modified: defaultModifiedData,
  showCloseEditorModal: false,
  showDeleteChangesDatasetModal: false,
  showSubmitDatasetModal: false,
  showUserChangesModal: false,
  showSubmitVersionModal: false,
  showImageModal: false,
  tableChanges: [],
  dataImages: {
    columnImages: []
  },
  imagesInformation: {
    images: [],
    showRefreshButton: false
  },
  isImagesTabActive: true
}

class DataCuratorTool extends React.Component<DataCuratorToolProps, DataCuratorToolState> {
  private alertTimer: any

  public constructor(props: any) {
    super(props)

    if (this.props.location && this.props.location.state && this.props.location.state.file && this.props.location.state.datasetInformation) {
      this.state = {
        ...defaultState,
        datasetInformation: this.props.location.state.datasetInformation,
        file: this.props.location.state.file
      }
    } else {
      this.state = defaultState
    }
  }

  public render() {
    const originalTable: ReactTableInformation = this.state.original
    const modifiedTable: ReactTableInformation = this.state.modified
    const file: DatasetInformationFile | undefined = this.state.file
    const originalFilename: string = DatasetUtil.getOriginalFilename(file)

    return <div className={'data-curator-tool'}>
      <Helmet>
        <title>{`AHEAD - Data Curator Tool - ${file?.filename}`}</title>
      </Helmet>
      {this.getAlerts()}
      <div className={'data-curator-tool-dataset-name-container'}>
        <h1>
          {this.state.datasetInformation?.title}
        </h1>
      </div>
      <Row className={'data-curator-tool-tables-container'}>
        <Col md={6} className={'ahead-table-column ahead-table-left'}>
          <Row className={'data-curator-tool-actions'}>
            <ButtonWithTextAndCross buttonText={'Close editor'} onClickCallback={() => this.handleCloseEditorClick()} />
          </Row>
          <Row className={'data-curator-tool-filename'}>
            {originalFilename}
          </Row>
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab className={'react-tabs__tab ahead-images-tab btn btn-light'}
                onClick={() => this.setState({ isImagesTabActive: true })}>Images</Tab>
              <img className={'ahead-tabs-divider'} alt="tabs divider icon"
                src={(this.state.isImagesTabActive ? AssetsConstants.TABS_DIVIDER_ICON_URL : AssetsConstants.TABS_DIVIDER_ICON_2_URL)} />
              <Tab className={'react-tabs__tab ahead-dataset-tab btn btn-light'}
                onClick={() => this.setState({ isImagesTabActive: false })}>Original file</Tab>
              <span
                className={'ahead-column-number'}>{AheadTableUtil.getColumnNumbersText(modifiedTable, this.state.currentTablePageIndex)}</span>
              <Button variant={'link'} className={'ahead-complete-image-link'}
                hidden={!this.state.imagesInformation.originalImage}
                onClick={() => this.loadOriginalImage(this.state.imagesInformation.originalImage)
                }>View complete image</Button>
            </TabList>
            <TabPanel>
              <div className={'ahead-data-curator-image-container'}>
                <SizeMe>
                  {
                    ({ size }) => this.renderImagesTab(this.state.dataImages.columnImages, size.width || 0, this.state.selectedCell)
                  }
                </SizeMe>
              </div>
            </TabPanel>
            <TabPanel>
              {this.renderTable(originalTable, DATA_CURATOR_TABLE_RHS_INDEX)}
            </TabPanel>
          </Tabs>
        </Col>
        <Col md={6} className={'ahead-table-column ahead-table-right'}>
          <Row className={'data-curator-tool-actions'} />
          <Row className={'data-curator-tool-filename'}>
            {modifiedTable.data.length > 0 ? `Working copy of ${originalFilename}` : originalTable.data.length > 0 ? 'Waiting for data...' : ''}
          </Row>
          {this.renderDatasetActionButtons()}
          {this.renderTable(modifiedTable, DATA_CURATOR_TABLE_LHS_INDEX, true)}
          {this.renderModifiedTableActionButtons()}
        </Col>
      </Row>
      <EditorCloseModal
        show={this.state.showCloseEditorModal}
        closeButtonCallback={() => this.onCloseEditorModal()} />
      <EditorDeleteChangesModal
        show={this.state.showDeleteChangesDatasetModal}
        closeButtonCallback={() => this.onCloseDeleteChangesModal()}
        deleteChangesButtonCallback={() => this.deleteModifiedTableData()}
      />
      <EditorSubmitVersionModal
        show={this.state.showSubmitVersionModal}
        closeButtonCallback={() => this.onCloseSubmitVersionModal()}
        submitNewVersionCallback={() => this.onSubmitNewVersion(this.state.originalCsv, this.state.tableChanges)} />
      <InformationModal
        show={this.state.showSubmitDatasetModal}
        informationBody={this.state.submitDatasetModalContent}
        closeButtonCallback={() => this.onCloseSubmitDatasetModal()} />
      <InformationModal
        show={this.state.showUserChangesModal}
        informationBody={this.state.userChangesModalContent}
        closeButtonCallback={() => this.onCloseUserChangesModal()} />
      <ImageModal
        show={this.state.showImageModal}
        originalImage={this.state.dataImages.originalImage}
        closeButtonCallback={() => this.onCloseImageModal()} />
      <Prompt
        when={this.state.tableChanges.length > 0 && !this.state.showSubmitDatasetModal && !this.state.showCloseEditorModal}
        message={'There are some changes done to the dataset, are you sure you want to leave the data curation page?'}
      />
      <LoadingOverlay isShowing={this.state.overlay.isShowing} />
    </div>
  }

  public async componentDidMount() {
    window.scrollTo(0, 0)
    this.getImagesData()
    this.getTableData()
  }

  public componentDidUpdate(): void {
    if (this.state.tableChanges.length > 0) {
      window.addEventListener('beforeunload', this.keepOnPage)
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener('beforeunload', this.keepOnPage)
    clearTimeout(this.alertTimer)
  }

  private keepOnPage(e: BeforeUnloadEvent): void {
    e.returnValue = 'There are some changes done to the dataset, are you sure you want to exit?'
  }

  private async getTableData() {
    const file: DatasetInformationFile | undefined = this.state.file

    if (file) {
      this.setState({ overlay: { isShowing: true } })

      try {
        const response = await DataverseService.getFile(file)
        this.loadInitialTableData(response)
      } catch {
        this.setState({ overlay: { isShowing: false } })
      }
    }
  }

  private getImagesData(alert?: AlertState) {
    const file: DatasetInformationFile | undefined = this.state.file

    this.setState({
      alert: alert ? alert : this.state.alert,
      overlay: {
        isShowing: true
      }
    })

    if (file) {
      const filename = file.filename.split('.')[0]
      const datasetId = this.state.datasetInformation ? this.state.datasetInformation.id : 0
      DataverseService
        .getImagesInformation(datasetId, filename)
        .then((getImagesInformationResponse: AxiosResponse) => {
          const imagesInformation: ImagesInformation = getImagesInformationResponse.data
          const columnImages: ImageInformation[] = imagesInformation.columnImages
          if (columnImages && columnImages.length > 0) {
            this.loadImage(columnImages[0])
          }
          this.handleGetImagesInformationResponse(getImagesInformationResponse.status, imagesInformation)
        })
        .catch((error: AxiosError) => {
          const errorStatus = error.response ? error.response.status : HttpStatus.BAD_REQUEST
          this.handleGetImagesInformationResponse(errorStatus, undefined)
        })
        .finally(() => {
          this.autoCloseAlert()
        })
    }
  }

  private handleGetImagesInformationResponse = (status: number, imagesInformation?: ImagesInformation) => {
    this.setState({
      alert: HttpResponsesUtil.getImagesInformationAlert(status),
      imagesInformation: {
        images: imagesInformation ? imagesInformation.columnImages : this.state.imagesInformation.images,
        originalImage: imagesInformation ? imagesInformation.originalImage : this.state.imagesInformation.originalImage,
        showRefreshButton: status === HttpStatus.ACCEPTED
      },
      overlay: {
        // only dismiss the overlay when we already have data available
        isShowing: !this.state.modified.data
      }
    })
  }

  private getAlerts(): JSX.Element {
    const { alert } = this.state
    return (
      <div className="data-curator-tool-alerts-container" onClick={this.handleCloseAlert}>
        <Alert
          variant={alert ? alert.variant : 'info'}
          show={alert ? alert.isShowing : false}
          onClose={this.handleCloseAlert}
          className={'data-curator-tool-alert'}
        >
          <p>{alert ? alert.message : ''}</p>
        </Alert>
      </div>
    )
  }

  private handleCloseAlert = () => {
    this.setState({
      alert: {
        isShowing: false
      }
    })
  }

  private autoCloseAlert() {
    clearTimeout(this.alertTimer)

    this.alertTimer = setTimeout(() => {
      this.setState({
        alert: { isShowing: false }
      })
    }, ALERT_MESSAGE_DURATION)
  }

  private renderImagesTab(columnImages: DatasetDataImage[], width: number, selectedCell?: SelectedCell): JSX.Element {
    const imagesInformation: ImageInformation[] | undefined = this.state.imagesInformation.images

    if (this.state.imagesInformation.showRefreshButton) {
      return (
        <Row className={'justify-content-center'}>
          <Button
            variant={'secondary'}
            className={'refresh-images-button'}
            onClick={() => this.getImagesData()}>Refresh images</Button>
        </Row>
      )
    }

    if (!imagesInformation || imagesInformation.length === 0) {
      return <span>No images available for this file</span>
    }

    const selectedImageColumn: number = selectedCell ? Number(selectedCell.col) : 0

    if (imagesInformation && !columnImages[selectedImageColumn]) {
      this.loadImage(imagesInformation[selectedImageColumn], selectedImageColumn)
      return (
        <LoadingSpinner text={'Downloading image...'} />
      )
    }

    const columnImage: DatasetDataImage = columnImages[selectedImageColumn]
    const resizedImageWidth: number = columnImage.dataImage.width
    const resizedImageHeight: number = columnImage.dataImage.height
    const originalWidthRatio: number = resizedImageWidth / columnImage.originalWidth
    const imageScalingRatio: number = resizedImageWidth > 0 ? width / resizedImageWidth : width
    const canvasMultiplier: number = originalWidthRatio * imageScalingRatio

    if (selectedCell) {
      this.handleImageScroll(selectedCell, canvasMultiplier)
    }

    return (
      <Stage
        container={'.ahead-data-curator-image-container'}
        className={'ahead-data-curator-image'}
        width={width}
        on
        height={resizedImageHeight * imageScalingRatio}>
        <Layer>
          {this.renderImage(columnImage.dataImage, width, Math.floor(resizedImageHeight * imageScalingRatio))}
          {this.renderSelectedCellHighlight(selectedCell, width, canvasMultiplier)}
          {this.renderModifiedCellsHighlight([...this.state.tableChanges], this.state.modified.data, selectedImageColumn, width, originalWidthRatio * imageScalingRatio)}
        </Layer>
      </Stage>
    )
  }

  private renderDatasetActionButtons() {
    const tableChangesCount: number = this.state.tableChanges.length

    return (
      <Row className={'ahead-table-dataset-actions'}>
        {
          tableChangesCount === 0 ?
            <Button
              variant={'secondary'}
              className={'load-latest-dataset-changes-button'}
              disabled={!isAuthenticated()}
              onClick={() => this.handleLoadDatasetChangesClick()}>Load your changes
            </Button>
            :
            <div className={'dataset-actions-buttons'}>
              <Button
                variant={'secondary'}
                className={'delete-dataset-changes-button'}
                disabled={tableChangesCount === 0}
                onClick={() => this.handleDeleteChangesDatasetClick()}>Delete changes
              </Button>
              <Button
                variant={'secondary'}
                className={'save-dataset-changes-button'}
                disabled={!isAuthenticated()}
                onClick={() => this.handleSaveDatasetChangesClick()}>Save changes
              </Button>
            </div>
        }
        <Col className={'dataset-changes-col'}>
          <p className={DatasetUtil.getDatasetChangesClassnameText(tableChangesCount)}>
            {DatasetUtil.getDatasetChangesCountText(tableChangesCount)}
          </p>
        </Col>
      </Row>
    )
  }

  private renderTable(table: ReactTableInformation, dataCuratorTableIndex: number, isModifiedTable = false): JSX.Element {
    return (
      <div onScroll={(event: React.UIEvent<HTMLDivElement>) => {
        this.handleTableScroll(event, dataCuratorTableIndex)
      }} className={`ahead-table${isModifiedTable ? '-modified' : ''}`}>
        <AheadTable
          columns={table.columns}
          data={table.data}
          currentPage={this.state.currentTablePageIndex}
          onPageChangeCallback={(page: number) => this.onPageChange(page)} />
      </div>
    )
  }

  private renderModifiedTableActionButtons(): JSX.Element | string {
    const hasTableChanges = this.state.tableChanges.length > 0

    if (hasTableChanges) {
      return <Row className={'ahead-table-modified-update-actions float-right'}>
        <Button
          variant={'secondary'}
          className={'submit-new-version-dataset-button'}
          disabled={!isAuthenticated()}
          onClick={() => this.handleSubmitVersionClick()}
        >Submit new version</Button>
      </Row>
    }
    return ''
  }

  private renderCell = (cellInfo: CellInfo, isModifiedTable: boolean): JSX.Element => {
    const originalTable: ReactTableInformation = this.state.original
    const modifiedTable: ReactTableInformation = this.state.modified
    const header: string = cellInfo.column.id ? cellInfo.column.id : ''
    const isModifiedCell: boolean = AheadTableUtil.isModifiedCell(cellInfo, originalTable.data, modifiedTable.data)
    const cellClassName: string = isModifiedCell ? 'ahead-table-cell-modified' : 'ahead-table-cell'
    const dropdownClassName: string = isModifiedCell ? 'ahead-table-dropdown-modified' : 'ahead-table-dropdown'

    if (isModifiedTable) {
      const boundingBoxCoordinates: BoundingBoxInformation = CanvasUtil.getBoundingBoxCoordinates(modifiedTable.data[cellInfo.index][HEADER_ROW_BOUNDING_BOX])

      if (header === HEADER_CLS) {
        return <div>
          <select
            aria-label={'Classification dropdown'}
            className={dropdownClassName}
            onClick={() => this.updateSelectedRow(modifiedTable, cellInfo.index)}
            onChange={event => this.handleCellChange(event.target.value, cellInfo, originalTable, modifiedTable, boundingBoxCoordinates)}
            value={modifiedTable.data[cellInfo.index][HEADER_CLS]}>
            {this.getDropdownOptions(originalTable)}
          </select>
        </div>
      }

      return <div
        contentEditable={true}
        suppressContentEditableWarning
        className={cellClassName}
        onClick={(_event: React.MouseEvent<HTMLDivElement>) => {
          this.updateSelectedRow(modifiedTable, cellInfo.index)
        }}
        onBlur={(event: React.FocusEvent<HTMLDivElement>) => {
          const newCellContent: string = event.target.textContent ? event.target.textContent : ''
          this.handleCellChange(newCellContent, cellInfo, originalTable, modifiedTable, boundingBoxCoordinates)
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          if (event.keyCode === KeyDown.ENTER) {
            event.preventDefault()
          }
        }}>
        {modifiedTable.data[cellInfo.index][header]}
      </div>
    }

    return <div
      className={cellClassName}>
      {originalTable.data[cellInfo.index][header]}
    </div>
  }

  private handleCellChange(newCellContent: string,
    cellInfo: CellInfo,
    originalTable: ReactTableInformation,
    modifiedTable: ReactTableInformation,
    boundingBoxInformation: BoundingBoxInformation) {
    const header: string = cellInfo.column.id ? cellInfo.column.id : ''
    const originalContent: string = originalTable.data[cellInfo.index][header]
    const tableChanges: TableChange[] = [...this.state.tableChanges]

    modifiedTable.data[cellInfo.index][header] = newCellContent

    const index = tableChanges.findIndex(change =>
      change.row === originalTable.data[cellInfo.index][HEADER_ROW] &&
      change.col === originalTable.data[cellInfo.index][HEADER_COL] &&
      change.header === cellInfo.column.id
    )

    if (newCellContent === originalContent) {
      if (index >= 0) {
        tableChanges.splice(index, TABLE_CHANGE_DELETE_COUNT)
      }
    } else {
      if (index >= 0) {
        tableChanges[index].value = newCellContent
        tableChanges[index].timestamp = DatetimeUtil.getCurrentTimeStr()
      } else {
        tableChanges.push({
          row: originalTable.data[cellInfo.index][HEADER_ROW],
          col: originalTable.data[cellInfo.index][HEADER_COL],
          header: header,
          boundingBoxInformation: boundingBoxInformation,
          previousValue: originalContent,
          value: newCellContent,
          timestamp: DatetimeUtil.getCurrentTimeStr()
        })
      }
    }

    this.setState({
      modified: {
        columns: this.getColumns(
          modifiedTable.data,
          modifiedTable.columns.map((column: any) => column.Header),
          true
        ),
        data: modifiedTable.data
      },
      tableChanges: tableChanges
    })
  }

  private handleSaveDatasetChangesClick() {
    const file: DatasetInformationFile | undefined = this.state.file
    const dataset: DatasetInformation | undefined = this.state.datasetInformation

    if (dataset && file) {
      window.scrollTo(0, 0)

      this.setState({
        overlay: { isShowing: true }
      })

      DataverseService.addUserDatasetChanges(dataset, file, this.state.tableChanges)
        .then(_ => {
          this.setState({
            overlay: { isShowing: false },
            alert: {
              isShowing: true,
              message: AlertConstants.SAVE_CHANGES_SUCCESS,
              variant: AlertVariants.SUCCESS
            }
          })
        })
        .catch(_ => {
          this.setState({
            overlay: { isShowing: false },
            alert: {
              isShowing: true,
              message: AlertConstants.SAVE_CHANGES_ERROR,
              variant: AlertVariants.DANGER
            }
          })
        })
        .finally(() => {
          this.autoCloseAlert()
        })
    }
  }

  private async handleLoadDatasetChangesClick() {
    const dataset: DatasetInformation | undefined = this.state.datasetInformation
    const rootDataFileId: number = this.state.file?.rootDataFileId ? this.state.file.rootDataFileId : 0

    if (dataset) {
      this.loadTableChanges(dataset, rootDataFileId)
    }
  }

  private handleDeleteChangesDatasetClick(): void {
    this.setState({ showDeleteChangesDatasetModal: true })
  }

  private onCloseDeleteChangesModal(): void {
    this.setState({ showDeleteChangesDatasetModal: false })
  }

  private handleCloseEditorClick(): void {
    if (this.state.tableChanges.length === 0) {
      store.dispatch(goBack())
    } else {
      this.setState({ showCloseEditorModal: true })
    }
  }

  private onCloseEditorModal(): void {
    this.setState({ showCloseEditorModal: false })
  }

  private onCloseSubmitDatasetModal() {
    this.setState({
      showSubmitDatasetModal: false
    })
    store.dispatch(push(NetworkConstants.URL_HOME))
  }

  private onCloseUserChangesModal(): void {
    this.setState({ showUserChangesModal: false })
  }

  private handleSubmitVersionClick(): void {
    this.setState({ showSubmitVersionModal: true })
  }

  private onCloseSubmitVersionModal(): void {
    this.setState({ showSubmitVersionModal: false })
  }

  private onCloseImageModal(): void {
    this.setState({ showImageModal: false })
  }

  private handleTableScroll = (e: React.UIEvent<HTMLDivElement>, index: number): void => {
    const element: HTMLDivElement = e.target as HTMLDivElement

    const table: HTMLDivElement = document.querySelectorAll('.rt-table')[index] as HTMLDivElement
    if (table) {
      table.scrollLeft = element.scrollLeft
    }
  }

  private handleImageScroll(selectedCell: SelectedCell | undefined, multiplier: number): void {
    const imageContainer: HTMLDivElement = document.getElementsByClassName('ahead-data-curator-image-container')[0] as HTMLDivElement
    if (imageContainer && selectedCell) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const top = selectedCell.hasBoundingBox ? selectedCell.y * multiplier - imageContainer.offsetHeight / 2 : selectedCell.y * multiplier

      imageContainer.scrollTo({
        top: top
      })
    }
  }

  private loadInitialTableData = (result: string) => {
    const original = AheadTableUtil.getFilteredCsvData(result)
    const originalData = original.data
    const originalHeaders = this.getColumns(originalData, original.headers)

    const modified = AheadTableUtil.getFilteredCsvData(result)
    const modifiedData = modified.data
    const modifiedHeaders = this.getColumns(modifiedData, modified.headers, true)

    this.setState({
      overlay: {
        isShowing: false
      },
      originalCsv: result,
      original: {
        columns: originalHeaders,
        data: originalData
      },
      modified: {
        columns: modifiedHeaders,
        data: modifiedData
      }
    })
  }

  private onPageChange = (page: number): void => {
    const topTableItemIndex = TABLE_DEFAULT_PAGE_SIZE * page

    this.setState({
      currentTablePageIndex: page,
      selectedCell: AheadTableUtil.getSelectedCellFromTableIndex(this.state.modified, topTableItemIndex, false)
    })
  }

  private getColumns = (data: any[], headers: string[], isModifiedTable = false): ReactTableColumns[] => {
    const columns: ReactTableColumns[] = []

    if (!headers || headers.length === 0) {
      return columns
    }

    headers.forEach(element => {
      columns.push({
        Header: element,
        accessor: element,
        sortable: false,
        Cell: (cellInfo: CellInfo) => this.renderCell(cellInfo, isModifiedTable),
        width: AheadTableUtil.getColumnWidth(data, element)
      })
    })

    return columns.filter(column => AheadTableUtil.shouldDisplayHeader(column.Header))
  }

  private onSubmitNewVersion(originalCsv: string, tableChanges: TableChange[]): void {
    const data: any[] = AheadTableUtil.applyCsvChanges(originalCsv, tableChanges)
    const blob = new Blob([AheadTableUtil.convertToCSV(data)], { type: 'text/plain;charset=utf-8' })
    const changePercentage = tableChanges.length / this.state.original.data.length * PERCENTAGE

    this.setState({
      overlay: { isShowing: true }
    })
    if (this.state.datasetInformation && this.state.file) {
      DataverseService
        .submitNewDatasetVersion(this.state.datasetInformation, this.state.file, blob, changePercentage)
        .then(result => {
          this.setState({
            overlay: { isShowing: false },
            showSubmitDatasetModal: true,
            showSubmitVersionModal: false,
            submitDatasetModalContent: HttpResponsesUtil.getSubmitNewDatasetResponseMessage(result.status)
          })
        }).catch((error: AxiosError) => {
          window.scrollTo(0, 0)
          const errorResponse = error.response
          const errorMessage = errorResponse && errorResponse.data.message ? errorResponse.data.message : HttpResponsesUtil.getSubmitNewDatasetResponseMessage().body
          this.setState({
            overlay: { isShowing: false },
            showSubmitVersionModal: false,
            alert: {
              isShowing: true,
              message: errorMessage,
              variant: AlertVariants.DANGER
            }
          })
          this.autoCloseAlert()
        })
    }
  }

  private renderSelectedCellHighlight(selectedCell: SelectedCell | undefined, width: number, multiplier: number): JSX.Element {
    let rectangle: JSX.Element = <Rect />

    if (selectedCell && selectedCell.hasBoundingBox) {
      rectangle = <Rect
        x={0}
        y={selectedCell.y * multiplier}
        height={selectedCell.height * multiplier}
        width={width}
        fill={'transparent'}
        stroke={'#f99e19'}
        strokeWidth={3}
      />
    }

    return rectangle
  }

  private renderModifiedCellsHighlight(tableChanges: TableChange[], data: any[], selectedImageColumn: number, width: number, multiplier: number): JSX.Element[] {
    const modifiedCells: JSX.Element[] = []

    if (tableChanges.length <= 0) {
      return modifiedCells
    }

    if (data && data.length <= 0) {
      return modifiedCells
    }

    const changes: TableChange[] = tableChanges.filter(change => Number(change.col) === selectedImageColumn)
    changes.forEach((change: TableChange) => {
      const boundingBoxInformation: BoundingBoxInformation = change.boundingBoxInformation

      modifiedCells.push(
        <Rect
          key={`${change.col}-${change.row}-${change.header}`}
          x={0}
          y={boundingBoxInformation.minY * multiplier}
          height={(boundingBoxInformation.maxY - boundingBoxInformation.minY) * multiplier}
          width={width}
          fill={'#f99e19'}
          globalCompositeOperation={'multiply'}
        />
      )
    })

    return modifiedCells
  }

  private renderImage(image: HTMLImageElement, width: number, height: number): JSX.Element {
    return (
      <Image image={image} width={width} height={height} />
    )
  }

  private cloneOriginalData(): CsvData {
    const originalCsv = AheadTableUtil.getFilteredCsvData(this.state.originalCsv)

    return {
      data: originalCsv.data,
      headers: this.getColumns(originalCsv.data, originalCsv.headers, true)
    }
  }

  private deleteModifiedTableData(): void {
    const { data, headers } = this.cloneOriginalData()

    this.setState({
      showDeleteChangesDatasetModal: false,
      modified: {
        columns: headers,
        data: data
      },
      tableChanges: []
    })
  }

  private loadImage(imageInformation: ImageInformation, index = 0) {
    const dataImage: HTMLImageElement = new window.Image()
    dataImage.src = imageInformation.url
    dataImage.onload = () => {
      const columnImages: DatasetDataImage[] = [...this.state.dataImages.columnImages]
      columnImages[index] = {
        filename: imageInformation.filename,
        dataImage: dataImage,
        originalWidth: imageInformation.imageProperties.width,
        originalHeight: imageInformation.imageProperties.height
      }

      this.setState({
        dataImages: {
          ...this.state.dataImages,
          columnImages: columnImages
        }
      })
    }
    dataImage.onerror = (_) => {
      this.setState({
        imagesInformation: {
          ...this.state.imagesInformation,
          showRefreshButton: true
        }
      })
    }
  }

  private loadOriginalImage(imageInformation: ImageInformation | undefined): void {
    this.setState({
      overlay: { isShowing: true }
    })

    if (imageInformation) {
      const dataImage: HTMLImageElement = new window.Image()
      dataImage.src = imageInformation.url
      dataImage.onload = () => this.handleLoadOriginalImage(imageInformation, dataImage)
      dataImage.onerror = () => this.handleLoadOriginalImageError()
    }
  }

  private handleLoadOriginalImage(imageInformation: ImageInformation, dataImage: HTMLImageElement) {
    this.setState({
      dataImages: {
        ...this.state.dataImages,
        originalImage: {
          filename: imageInformation.filename,
          dataImage: dataImage,
          originalWidth: imageInformation.imageProperties.width,
          originalHeight: imageInformation.imageProperties.height
        }
      },
      showImageModal: true,
      overlay: { isShowing: false }
    })
  }

  private handleLoadOriginalImageError() {
    const alert: AlertState = {
      isShowing: true,
      message: AlertConstants.LOAD_ORIGINAL_IMAGE_ERROR,
      variant: AlertVariants.DANGER
    }
    this.getImagesData(alert)
  }

  private loadTableChanges(datasetInformation: DatasetInformation, rootDataFileId: number): void {
    const { data, headers } = this.cloneOriginalData()

    this.setState({
      overlay: { isShowing: true }
    })

    DataverseService
      .getUserDatasetChanges(datasetInformation.id, rootDataFileId)
      .then(result => {
        const latestUserChange: UserChanges = [...result.data.Items].pop()
        const tableChanges: TableChange[] = AheadTableUtil.filterTableChanges(this.state.originalCsv, latestUserChange.changes)
        const state: any = {
          overlay: { isShowing: false },
          tableChanges,
          modified: {
            columns: headers,
            data: AheadTableUtil.getDataFromTableChanges(this.state.originalCsv, tableChanges)
          }
        }

        if ((latestUserChange.versionMinorNumber !== datasetInformation.versionMinorNumber ||
          latestUserChange.versionNumber !== datasetInformation.versionNumber) && tableChanges.length > 0) {
          state.showUserChangesModal = true
          state.userChangesModalContent = {
            title: 'Version mismatch',
            body: `Latest dataset version is: <b>${datasetInformation.versionNumber}.${datasetInformation.versionMinorNumber}</b><br/><br/>Your changes were made in version: <b>${latestUserChange.versionNumber}.${latestUserChange.versionMinorNumber}</b>`
          }
        }

        this.setState(state)
      })
      .catch(_ => {
        this.setState({
          overlay: { isShowing: false },
          modified: {
            columns: headers,
            data: data
          }
        })
      })
  }

  private updateSelectedRow(tableData: ReactTableInformation, index: number) {
    this.setState({
      selectedCell: AheadTableUtil.getSelectedCellFromTableIndex(tableData, index)
    })
  }

  private getDropdownOptions(originalTable: ReactTableInformation): JSX.Element[] {
    const classifications: string[] = [...originalTable.data].map(item => item[HEADER_CLS])
      .filter((value, index, array) => array.indexOf(value) === index)

    return classifications.map(classification =>
      <option
        key={classification}>
        {classification}
      </option>)
  }
}

export default DataCuratorTool
