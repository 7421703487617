import {
  COORDINATES_BOUNDING_BOX_REGEX,
  COORDINATES_MIN_VALUE,
  COORDINATES_REGEX_MATCH_INDEX, COORDINATES_SEPARATOR, COORDINATES_X_INDEX, COORDINATES_Y_INDEX
} from './DataCuratorConstants'
import { BoundingBoxInformation } from '../types/canvas'

export class CanvasUtil {
  public static getCoordinateValue(coordinate: string): number {
    const coordinateValue = Number(coordinate.trim())

    if (isNaN(coordinateValue)) {
      return COORDINATES_MIN_VALUE
    }

    return Math.floor(coordinateValue > COORDINATES_MIN_VALUE ? coordinateValue : COORDINATES_MIN_VALUE)
  }

  public static getBoundingBoxCoordinates(rawBoundingBoxText: string): BoundingBoxInformation {
    const regex = new RegExp(COORDINATES_BOUNDING_BOX_REGEX)
    const matches = regex.exec(rawBoundingBoxText)

    if (!matches) {
      return {
        minX: 0,
        minY: 0,
        maxY: 0
      }
    } else {
      const xCoordinates: number[] = []
      const yCoordinates: number[] = []

      matches.splice(COORDINATES_REGEX_MATCH_INDEX).forEach(coordinateStr => {
        const coordinate = coordinateStr
          .split(COORDINATES_SEPARATOR)
          .map(CanvasUtil.getCoordinateValue)

        xCoordinates.push(coordinate[COORDINATES_X_INDEX])
        yCoordinates.push(coordinate[COORDINATES_Y_INDEX])
      })

      return {
        minX: xCoordinates.reduce((previousValue, currentValue) => Math.min(previousValue, currentValue)),
        minY: yCoordinates.reduce((previousValue, currentValue) => Math.min(previousValue, currentValue)),
        maxY: yCoordinates.reduce((previousValue, currentValue) => Math.max(previousValue, currentValue)),
      }
    }
  }
}