import { MessageState } from '../types/messageState'

export const getInitialUsername = (propsState: any): string => {
  return propsState && propsState.username !== undefined
    ? propsState.username
    : ''
}

export const getInitialErrorMessage = (propsState: any): MessageState => {
  return {
    isShowing: propsState ? propsState.errorMessage !== undefined : false,
    message:
      propsState && propsState.errorMessage !== undefined
        ? propsState.errorMessage
        : ''
  }
}

export const getInitialInfoMessage = (propsState: any): MessageState => {
  return {
    isShowing: propsState ? propsState.infoMessage !== undefined : false,
    message:
      propsState && propsState.infoMessage !== undefined
        ? propsState.infoMessage
        : ''
  }
}
