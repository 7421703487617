import React from 'react'
import { Button, Modal, Row } from 'react-bootstrap'
import unauthenticatedEditModal from '../../assets/unauthenticated_edit_modal.json'
import ModalCloseIcon from '../atoms/ModalCloseIcon'
import ModalContent from '../atoms/ModalContent'
import store from '../../stores/store'
import { push } from 'connected-react-router'
import { NetworkConstants } from '../../utils/NetworkConstants'

interface UnauthenticatedEditModalProps {
  show: boolean
  closeButtonCallback: () => void
  prevLocation?: {
    url: string
    data: any
  }
}

class UnauthenticatedEditModal extends React.Component<UnauthenticatedEditModalProps> {
  public render() {
    return (
      <Modal
        className={'ahead-modal'}
        size="lg"
        centered
        show={this.props.show}
        onHide={this.props.closeButtonCallback}>
        <Modal.Body>
          <ModalCloseIcon
            onCloseIconClicked={this.props.closeButtonCallback}
            altText={'close modal icon'} />
          <ModalContent title={unauthenticatedEditModal.title}
            bodyText={unauthenticatedEditModal.body}
            signature={unauthenticatedEditModal.signature} />
          <Row className={'ahead-modal-action-buttons'}>
            <Button
              className={'ahead-modal-action-login'}
              onClick={() => this.onLoginButtonClicked()}>Log in</Button>
            <Button
              className={'ahead-modal-action-signup'}
              onClick={() => this.onSignUpButtonClicked()}>Sign up</Button>
            <Button
              className={'ahead-modal-action-cancel'}
              variant={'secondary'}
              onClick={this.props.closeButtonCallback}>Cancel</Button>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }

  private onLoginButtonClicked(): void {
    if (this.props.prevLocation) {
      store.dispatch(push({
        pathname: NetworkConstants.URL_LOGIN,
        state: {
          prevLocation: this.props.prevLocation
        }
      }))
    } else {
      store.dispatch(push(NetworkConstants.URL_LOGIN))
    }
  }

  private onSignUpButtonClicked() {
    store.dispatch(push(NetworkConstants.URL_SIGN_UP))
  }
}

export default UnauthenticatedEditModal