export const FIRST_CHARACTER = 0

export const PAGINATION_FIRST_PAGE_INDEX = 1
export const PAGINATION_MAX_PAGES_DISPLAYED = 5
export const PAGINATION_DEFAULT_ITEMS_PER_PAGE = 10

export const VERSION_DEFAULT_MAJOR_VERSION = 1
export const VERSION_DEFAULT_MINOR_VERSION = 0

export const TEXT_DATASET_TITLE_MAX_LENGTH = 80
export const TEXT_DATASET_DESCRIPTION_MAX_LENGTH = 200

export const FILES_PLURAL_INDEX = 2
export const FILES_CSV_EXTENSION = '.csv'

export const TABLE_DEFAULT_PAGE_SIZE = 10
export const TABLE_CHANGE_DELETE_COUNT = 1

export const DATA_CURATOR_TABLE_LHS_INDEX = 0
export const DATA_CURATOR_TABLE_RHS_INDEX = 1

export const FILEBLOB_BASE64STRING_INDEX = 1

export const AHEAD_TABLE_COLUMN_SPACING = 12
export const AHEAD_TABLE_COLUMN_EXTRA_SPACING = 15
export const AHEAD_TABLE_ONE_COLUMN = 1

export const PERCENTAGE = 100

export const DATASET_CHANGES_PLURAL_INDEX = 2

export const ALERT_MESSAGE_DURATION = 5000
