import React from 'react'
import Interweave from 'interweave'

interface PostBodyProps {
  body?: string
}

class PostBody extends React.Component<PostBodyProps> {

  public render() {
    return (
      <div className={'post-body'}>
        <Interweave content={this.props.body ? this.props.body : ''} />
      </div>
    )
  }
}

export default PostBody