import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import DatasetsGrid from '../organisms/DatasetsGrid'
import { Col } from 'react-bootstrap'
import SearchInput from '../atoms/SearchInput'
import { DataverseService } from '../../api/dataverseService'
import store from '../../stores/store'
import { push } from 'connected-react-router'
import LoadingSpinner from '../atoms/LoadingSpinner'
import { DatasetSearchInformation } from '../../types/dataverse/dataset'

const Home = (): JSX.Element => {
  const [latestDatasetSearchInformation, setLatestDatasetSearchInformation] = useState<DatasetSearchInformation[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    let isMounted = true

    DataverseService
      .getLatestDatasets()
      .then((result: any) => {
        if (isMounted) {
          setLatestDatasetSearchInformation(result)
        }
      })
      .catch((_: any) => {
        if (isMounted) {
          setLatestDatasetSearchInformation([])
        }
      }).finally(() => {
        setIsLoading(false)
      })

    return () => {
      isMounted = false
    }
  }, [])

  const handleSubmit = (input: string) => {
    const query = input ? input : '*'
    store.dispatch(
      push({
        pathname: '/search',
        state: { query }
      })
    )
  }

  return (
    <div className='home'>
      <div className='home-search-container'>
        <Col md={{ span: 4, offset: 4 }}>
          <SearchInput onSubmitCallback={handleSubmit} />
        </Col>
      </div>
      <h2 className='home-highlights-text'>
        New Datasets / Highlights
      </h2>
      <hr className='home-highlights-separator' />
      <Row className='home-highlights-body'>
        <Col>
          {isLoading ? <LoadingSpinner /> : <DatasetsGrid datasetSearchInformationList={latestDatasetSearchInformation} />}
        </Col>
      </Row>
    </div>
  )
}

export default Home
