import React from 'react'
import { Pagination } from 'react-bootstrap'
import { PaginationUtil } from '../../utils/PaginationUtil'

interface PaginationProps {
  currentPage: number
  numberOfPages: number
  pageSelectedCallback?: any
  firstPageCallback?: any
  previousPageCallback?: any
  nextPageCallback?: any
  lastPageCallback?: any
}

class PaginationBar extends React.Component<PaginationProps> {
  public render() {
    return <div className={'pagination-container'}>
      {
        this.props.currentPage > 0 && this.props.currentPage <= this.props.numberOfPages ?
          <Pagination className={'pagination-bar'}>
            {this.getPaginationBackwardButtons(this.props.currentPage, this.props.numberOfPages)}
            {this.getPaginationLeftEllipsis(this.props.currentPage, this.props.numberOfPages)}
            {this.getPaginationItems(this.props.currentPage, this.props.numberOfPages)}
            {this.getPaginationRightEllipsis(this.props.currentPage, this.props.numberOfPages)}
            {this.getPaginationFowardButtons(this.props.currentPage, this.props.numberOfPages)}
          </Pagination> : ''
      }
    </div>
  }

  private getPaginationBackwardButtons(currentPage: number, numberOfPages: number): JSX.Element[] {
    const isDisabled: boolean = PaginationUtil.shouldDisablePaginationBackward(currentPage, numberOfPages)
    return [
      <Pagination.First
        aria-label={'Go to first page button'}
        className={'pagination-first-button'}
        disabled={isDisabled}
        key={'pagination-first'}
        onClick={(event: any) => {
          event.preventDefault()
          this.props.firstPageCallback()
        }}>{'<<'}</Pagination.First>,
      <Pagination.Prev
        aria-label={'Go to previous page button'}
        className={'pagination-previous-button'}
        disabled={isDisabled}
        key={'pagination-previous'}
        onClick={(event: any) => {
          event.preventDefault()
          this.props.previousPageCallback()
        }}>{'< Previous'}</Pagination.Prev>
    ]
  }

  private getPaginationLeftEllipsis(currentPage: number, numberOfPages: number): JSX.Element | string {
    return PaginationUtil.shouldShowLeftEllipsis(currentPage, numberOfPages) ?
      <Pagination.Ellipsis disabled className={'pagination-left-ellipsis'} /> : ''
  }

  private getPaginationItems(currentPage: number, numberOfPages: number): JSX.Element[] {
    const items: JSX.Element[] = []
    const maxItemsDisplayed = PaginationUtil.getMaxItemsDisplayed(numberOfPages)
    const startPosition = PaginationUtil.getStartingPosition(currentPage, numberOfPages, maxItemsDisplayed)

    for (let i = startPosition;i < startPosition + maxItemsDisplayed && startPosition !== 0;i++) {
      items.push(<Pagination.Item
        aria-label={`Go to page ${i} button`}
        className={'pagination-page-button'}
        active={i === currentPage}
        key={`pagination-page-${i}`}
        onClick={(event: any) => {
          event.preventDefault()
          this.props.pageSelectedCallback(i)
        }}>{i}</Pagination.Item>)
    }

    return items
  }

  private getPaginationRightEllipsis(currentPage: number, numberOfPages: number): JSX.Element | string {
    return PaginationUtil.shouldShowRightEllipsis(currentPage, numberOfPages) ?
      <Pagination.Ellipsis
        disabled
        className={'pagination-right-ellipsis'} /> : ''
  }

  private getPaginationFowardButtons(currentPage: number, numberOfPages: number): JSX.Element[] {
    const isDisabled: boolean = PaginationUtil.shouldDisablePaginationForward(currentPage, numberOfPages)

    return [
      <Pagination.Next
        aria-label={'Go to next page button'}
        className={'pagination-next-button'}
        disabled={isDisabled}
        key={'pagination-next'}
        onClick={(event: any) => {
          event.preventDefault()
          this.props.nextPageCallback()
        }}>{'Next >'}</Pagination.Next>,
      <Pagination.Last
        aria-label={'Go to last page button'}
        className={'pagination-last-button'}
        disabled={isDisabled}
        key={'pagination-last'}
        onClick={(event: any) => {
          event.preventDefault()
          this.props.lastPageCallback()
        }}>{'>>'}</Pagination.Last>
    ]
  }
}

export default PaginationBar