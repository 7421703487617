import { DatasetInformation, DatasetInformationFile } from '../types/dataverse/dataset'
import {
  DATASET_CHANGES_PLURAL_INDEX,
  FILES_CSV_EXTENSION,
  FILES_PLURAL_INDEX,
  FIRST_CHARACTER, TEXT_DATASET_DESCRIPTION_MAX_LENGTH,
  TEXT_DATASET_TITLE_MAX_LENGTH,
  VERSION_DEFAULT_MAJOR_VERSION,
  VERSION_DEFAULT_MINOR_VERSION
} from './Constants'
import { DATASET_FILE_ORIGINAL_FORMAT_CSV, DATASET_VERSION_STATE_RELEASED } from './DatasetConstants'

export class DatasetUtil {
  public static getKeywords(datasetInformation: DatasetInformation, separator = ', '): string {
    if (!datasetInformation.keywords) {
      return ''
    }

    return datasetInformation.keywords.join(separator)
  }

  public static getVersion(datasetInformation: DatasetInformation): string {
    if (datasetInformation.versionNumber === undefined || datasetInformation.versionMinorNumber === undefined) {
      return ''
    }

    return `${datasetInformation.versionNumber}.${datasetInformation.versionMinorNumber}`
  }

  public static isOriginalVersion(datasetInformation: DatasetInformation): boolean {
    const versionNumber = datasetInformation.versionNumber
    const versionMinorNumber = datasetInformation.versionMinorNumber

    if (!versionNumber) {
      return true
    }

    if (versionNumber === VERSION_DEFAULT_MAJOR_VERSION && versionMinorNumber === VERSION_DEFAULT_MINOR_VERSION) {
      return true
    }

    return false
  }

  public static getTrimmedTitle(datasetInformation: DatasetInformation): string {
    if (!datasetInformation.title) {
      return ''
    }

    const title: string = datasetInformation.title

    if (title.length > TEXT_DATASET_TITLE_MAX_LENGTH) {
      return `${title.substring(FIRST_CHARACTER, TEXT_DATASET_TITLE_MAX_LENGTH)}...`
    }

    return title
  }

  public static getTrimmedDescription(datasetInformation: DatasetInformation): string {
    if (!datasetInformation.description || !datasetInformation.description.text) {
      return ''
    }

    const descriptionText: string = datasetInformation.description.text

    if (descriptionText.length > TEXT_DATASET_DESCRIPTION_MAX_LENGTH) {
      return `${descriptionText.substring(FIRST_CHARACTER, TEXT_DATASET_DESCRIPTION_MAX_LENGTH)}...`
    }

    return descriptionText
  }

  public static getMetadataText(lastUpdatedMonthYear: string, numberOfFiles: number, keywords: string): string {
    let metadataText = `${lastUpdatedMonthYear} | ${this.getNumberOfFilesText(numberOfFiles)}`

    if (keywords) {
      metadataText = `${metadataText} | ${keywords}`
    }

    return metadataText
  }

  public static getNumberOfFilesText(numberOfFiles: number): string {
    let numberOfFilesText = `${numberOfFiles} file`

    if (numberOfFiles === 0 || numberOfFiles >= FILES_PLURAL_INDEX) {
      numberOfFilesText = `${numberOfFilesText}s`
    }

    return numberOfFilesText
  }

  public static getNumberOfFiles(datasetInformation: DatasetInformation) {
    return datasetInformation.files ? datasetInformation.files.length : 0
  }

  public static getVersionNumberList(datasetsInformation: DatasetInformation[]): string[] {
    const versionNumberList: string[] = []

    datasetsInformation.forEach(datasetInformation => {
      versionNumberList.push(`${datasetInformation.versionNumber}.${datasetInformation.versionMinorNumber}`)
    })

    return versionNumberList
  }

  public static isEditableDatasetVersion(currentDatasetInformation?: DatasetInformation, latestDatasetInformation?: DatasetInformation): boolean {
    if (!currentDatasetInformation || !latestDatasetInformation) {
      return false
    }

    return this.getVersion(currentDatasetInformation) === this.getVersion(latestDatasetInformation) &&
      currentDatasetInformation.versionState === DATASET_VERSION_STATE_RELEASED
  }

  public static getOriginalFilename(file?: DatasetInformationFile): string {
    if (!file) {
      return ''
    }

    const filename: string = file.filename

    if (file.originalFileFormat === DATASET_FILE_ORIGINAL_FORMAT_CSV) {
      const extensionIndex = filename.lastIndexOf('.')
      return extensionIndex > 0 ? `${filename.substring(0, extensionIndex)}.csv` : filename
    }

    return filename
  }

  public static getDataverseLink(doi: string, datasetInformation: DatasetInformation): string {
    let version = ''

    if (datasetInformation.versionNumber !== undefined && datasetInformation.versionMinorNumber !== undefined) {
      version = `&version=${datasetInformation.versionNumber}.${datasetInformation.versionMinorNumber}`
    }

    return `${process.env.REACT_APP_DATAVERSE_HOST}/dataset.xhtml?persistentId=${doi}${version}`
  }

  public static isCsvFile(file: DatasetInformationFile): boolean {
    return file.filename.endsWith(FILES_CSV_EXTENSION) || file.originalFileFormat === DATASET_FILE_ORIGINAL_FORMAT_CSV
  }

  public static getDatasetChangesCountText(datasetChangesCount: number): string {
    let datasetChangesText = `${datasetChangesCount} change`

    if (!datasetChangesCount || datasetChangesCount >= DATASET_CHANGES_PLURAL_INDEX) {
      datasetChangesText += 's'
    }

    return datasetChangesText
  }

  public static getDatasetChangesClassnameText(datasetChangesCount: number): string {
    return datasetChangesCount ? 'dataset-changes' : 'dataset-no-changes'
  }

  public static getVersionState(versionState?: string): string {
    if (!versionState) {
      return ''
    }

    return versionState === DATASET_VERSION_STATE_RELEASED ? 'Updated' : 'Pending'
  }
}