import React from 'react'
import { Row } from 'react-bootstrap'
import Interweave from 'interweave'

interface ModalContentProps {
  title: string
  bodyText: string
  signature: string
}

const ModalContent = ({ title, bodyText, signature }: ModalContentProps): JSX.Element => {
  return (
    <div className={'ahead-modal-content'}>
      <Row className={'ahead-modal-content-title'}>
        <h1>{title}</h1>
      </Row>
      <Row className={'ahead-modal-content-body'}>
        <Interweave content={bodyText} />
      </Row>
      <Row
        className={'ahead-modal-content-signature'}>
        <Interweave content={signature} />
      </Row>
    </div>
  )
}

export default ModalContent