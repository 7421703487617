import React from 'react'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'

interface LoadingSpinnerProps {
  text?: string;
}

const LoadingSpinner = ({ text }: LoadingSpinnerProps): JSX.Element => {
  return (
    <div className="loading-spinner">
      <Row className="d-flex flex-row justify-content-center">
        <Spinner animation="border" />
      </Row>
      <Row className="d-flex flex-row justify-content-center">
        <h1 className="loading-text">{text ? text : ''}</h1>
      </Row>
    </div>
  )
}

export default LoadingSpinner
