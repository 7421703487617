import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { NetworkConstants } from '../../utils/NetworkConstants'
import footerJson from '../../assets/footer.json'
import LinksList from '../molecules/LinksList'
import { AssetsConstants } from '../../utils/AssetsConstants'

class Footer extends React.Component {
  public render() {
    return (
      <div className="footer">
        <hr className='footer-separator' />
        <Navbar expand="lg" className="p-3" aria-label="Footer navigation bar">
          <Col xs={2}>
            <Navbar.Brand href={NetworkConstants.URL_HOME}>
              <img
                alt="footer-brand-logo"
                src={AssetsConstants.LOGO_URL}
                className="d-inline-block align-top brand-logo"
              />
            </Navbar.Brand>
          </Col>
          <Col xs={3} className='footer-col'>
            <Row className="p-3">
              <p>Open-source under Apache 2.0 License</p>
              <p>Copyright © 2020, The President & Fellows of Harvard College </p>
            </Row>
            <Row className="p-3 footer-links">
              <LinksList title={footerJson.github.title} links={footerJson.github.links} />
            </Row>
          </Col>
          <Col xs={3} className='footer-col'>
            <Row className="p-3 footer-links">
              <LinksList title={footerJson.communityLinks.title} links={footerJson.communityLinks.links} />
            </Row>
            <Row className="p-3 footer-links">
              <LinksList title={footerJson.docsLinks.title} links={footerJson.docsLinks.links} />
            </Row>
          </Col>
          <Col xs={3} className='footer-col'>
            <Row className="p-3 footer-links">
              <LinksList title={footerJson.faqLinks.title} links={footerJson.faqLinks.links} />
            </Row>
            <Row className="p-3 footer-links">
              <LinksList title={footerJson.linksOfInterestLinks.title} links={footerJson.linksOfInterestLinks.links} />
            </Row>
            <Row className={'footer-ahead-version'}>
              AHEAD build v. {process.env.REACT_APP_VERSION}
            </Row>
          </Col>
        </Navbar>
      </div>
    )
  }
}

export default Footer
