import React from 'react'
import { Button, Row } from 'react-bootstrap'
import downloadModal from '../../assets/download_modal.json'
import { EndpointsHelper } from '../../api/endpointsHelper'
import store from '../../stores/store'
import { push } from 'connected-react-router'
import { NetworkConstants } from '../../utils/NetworkConstants'
import { DatasetInformation, DatasetInformationFile } from '../../types/dataverse/dataset'
import { FILES_CSV_EXTENSION } from '../../utils/Constants'
import { DatasetUtil } from '../../utils/DatasetUtil'
import AheadModal from './AheadModal'
import { isAuthenticated } from '../../utils/AuthenticationUtil'

interface DownloadModalProps {
  show: boolean
  file?: DatasetInformationFile
  datasetInformation?: DatasetInformation
  closeButtonCallback: () => void
  notLoggedInCallback?: any
  isEditableDatasetVersion: boolean
}

class DownloadModal extends React.Component<DownloadModalProps> {
  public render() {
    return (
      <AheadModal
        show={this.props.show}
        title={downloadModal.title}
        body={downloadModal.body}
        signature={downloadModal.signature}
        closeButtonCallback={() => this.props.closeButtonCallback()}>
        <Row className={'ahead-modal-action-buttons'}>
          <a
            href={EndpointsHelper.getDownloadFileEndpoint(this.props.file)}
            className="ahead-modal-action-start-download btn btn-primary"
            role="button" aria-pressed="true">
            Start download
          </a>
          {this.renderOpenEditorButton()}
        </Row>
      </AheadModal>
    )
  }

  private renderOpenEditorButton(): JSX.Element | string {
    const { isEditableDatasetVersion, file, datasetInformation } = this.props

    if (!file || !isEditableDatasetVersion) {
      return ''
    }

    const originalFileName = DatasetUtil.getOriginalFilename(file)

    if (originalFileName.endsWith(FILES_CSV_EXTENSION)) {
      return (
        <Button
          className={'ahead-modal-action-open-editor'}
          variant={'secondary'}
          onClick={() => isAuthenticated() ? this.onOpenEditorClicked(file, datasetInformation) : this.props.notLoggedInCallback(file)}>Open
          editor
        </Button>
      )
    }

    return ''
  }

  private onOpenEditorClicked(file?: DatasetInformationFile, datasetInformation?: DatasetInformation) {
    store.dispatch(push({
      pathname: NetworkConstants.URL_DATA_CURATOR_TOOL,
      state: {
        file,
        datasetInformation
      }
    }))
  }
}

export default DownloadModal