export class AlertConstants {
  public static CODE_VERIFICATION_SUCCESS = 'Code successfully verified.'
  public static CODE_VERIFICATION_RESEND = 'Your new verification code has been sent.'
  public static PASSWORD_UPDATE_SUCCESS = 'Password has been successfully updated.'
  public static PASSWORD_CHANGE_SUCCESS = 'Password has been successfully changed.'
  public static SAVE_CHANGES_SUCCESS = 'Your changes have been successfully saved.'
  public static SAVE_CHANGES_ERROR = 'Unable to save changes, please try again.'
  public static GET_IMAGES_INFORMATION_ACCEPTED = 'Images are being processed right now. Try again in a few seconds by clicking the refresh images button.'
  public static GET_IMAGES_INFORMATION_NOT_FOUND = 'No images were found for this dataset.'
  public static LOAD_ORIGINAL_IMAGE_ERROR = 'Original image currently not available, please try again.'
  public static GENERIC_ERROR = 'Something went wrong, please try again.'
}