import store from '../stores/store'
import { CognitoUser } from '@aws-amplify/auth'
import { AuthState } from '../types/authState'
import { UpdateAuthenticationStatus } from '../actions/auth'
import { Auth } from 'aws-amplify'

// TODO: add auth tests
export const getAuthStateFromCognitoUser = (user: CognitoUser): AuthState => {
  const cognitoUserSession = user.getSignInUserSession()
  if (cognitoUserSession) {
    const payload = cognitoUserSession.getAccessToken().decodePayload()
    const group = payload['cognito:groups']
    return {
      isAuthenticated: true,
      username: user.getUsername(),
      userGroups: group,
      userId: payload.sub
    }
  } else {
    return {
      isAuthenticated: false
    }
  }
}

export const setAuthState = (state: AuthState | null): void => {
  store.dispatch(UpdateAuthenticationStatus(state))
}

export const getAuthState = (): AuthState => {
  return store.getState()['authentication']['auth']
}

export const isAuthenticated = (): boolean => {
  const authState = getAuthState()
  return authState ? authState.isAuthenticated : false
}

export const getJwtToken = async (): Promise<string> => {
  return (await Auth.currentSession()).getIdToken().getJwtToken()
}

export const getUserName = (): string => {
  const authState = getAuthState()
  return authState && authState['username'] ? authState['username'] : ''
}
