import submitDatasetResponses from '../assets/submit_dataset_responses.json'
import HttpStatus from 'http-status-codes'
import { ModalInformationBody } from '../types/modal'
import { AlertState } from '../types/messageState'
import { AlertVariants } from './AlertUtils'
import { AlertConstants } from './AlertConstants'

export class HttpResponsesUtil {
  public static getSubmitNewDatasetResponseMessage(status = 0): ModalInformationBody {
    if (status === HttpStatus.OK || status === HttpStatus.CREATED) {
      return { title: submitDatasetResponses['responses'][status].title, body: submitDatasetResponses['responses'][status].body }
    } else {
      return { title: submitDatasetResponses['responses']['error'].title, body: submitDatasetResponses['responses']['error'].body }
    }
  }

  public static getImagesInformationAlert(status: number): AlertState {
    switch (status) {
      case HttpStatus.OK:
        return {
          isShowing: false
        }
      case HttpStatus.ACCEPTED:
        return {
          isShowing: true,
          message: AlertConstants.GET_IMAGES_INFORMATION_ACCEPTED,
          variant: AlertVariants.INFO
        }
      case HttpStatus.NOT_FOUND:
        return {
          isShowing: true,
          message: AlertConstants.GET_IMAGES_INFORMATION_NOT_FOUND,
          variant: AlertVariants.DANGER
        }
      default:
        return {
          isShowing: true,
          message: AlertConstants.GENERIC_ERROR,
          variant: AlertVariants.DANGER
        }
    }
  }
}