import React from 'react'
import { Row } from 'react-bootstrap'

interface LinkListItemProps {
  url: string
  text?: string
  newWindow?: boolean
}

const LinkListItem = ({ url, text, newWindow }: LinkListItemProps): JSX.Element => {
  const target = newWindow ? '_blank' : '_self'
  return (
    <Row className={'link-list-item'}>
      <a href={url} target={target}>{text ? text : url}</a>
    </Row>
  )
}

export default LinkListItem