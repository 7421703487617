import React from 'react'
import { DatasetSearchInformation } from '../../types/dataverse/dataset'
import Card from 'react-bootstrap/Card'
import { DatasetUtil } from '../../utils/DatasetUtil'
import { DatetimeUtil } from '../../utils/DatetimeUtil'
import { Link } from 'react-router-dom'
import { SearchUtil } from '../../utils/SearchUtil'

interface DatasetCardProps {
  datasetSearchInformation: DatasetSearchInformation
}

class DatasetCard extends React.Component<DatasetCardProps> {
  public render() {
    const datasetSearchInformation: DatasetSearchInformation = this.props.datasetSearchInformation
    const keywords: string = SearchUtil.getKeywords(datasetSearchInformation)
    const lastUpdatedMonthYear: string = DatetimeUtil.getMonthYearDate(datasetSearchInformation.updatedAt)
    const numberOfFiles: number = datasetSearchInformation.fileCount
    return (
      <Card className={'dataset-card-container'}>
        <Card.Body className={'dataset-card-body'}>
          <Card.Title className={'dataset-card-body-title'}>
            <Link
              to={{
                pathname: `/dataset/${datasetSearchInformation.entity_id}`
              }}
              className={'dataset-card-body-title-link'}>
              {SearchUtil.getTrimmedTitle(datasetSearchInformation)}{this.getVersionText(datasetSearchInformation)}
            </Link>
          </Card.Title>
          <Card.Text
            className={'dataset-card-body-text'}>{SearchUtil.getTrimmedDescription(datasetSearchInformation)}</Card.Text>
          <Card.Text
            className={'dataset-card-body-metadata'}>{DatasetUtil.getMetadataText(lastUpdatedMonthYear, numberOfFiles, keywords)}</Card.Text>
        </Card.Body>
      </Card>
    )
  }

  private getVersionText(datasetSearchInformation: DatasetSearchInformation): JSX.Element | string {
    if (SearchUtil.isOriginalVersion(datasetSearchInformation)) {
      return ''
    }

    const versionState: string = DatasetUtil.getVersionState(datasetSearchInformation.versionState)

    return <div>version {SearchUtil.getVersion(datasetSearchInformation)} <span
      className={versionState}>{versionState}</span></div>
  }
}

export default DatasetCard