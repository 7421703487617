import React from 'react'
import Form from 'react-bootstrap/Form'
import { InputGroup } from 'react-bootstrap'
import { AssetsConstants } from '../../utils/AssetsConstants'

interface SearchFileInputProps {
  placeholderText?: string
  onChangeCallback: any
}

export const SearchFileInput = ({ placeholderText, onChangeCallback }: SearchFileInputProps): JSX.Element => {
  return (
    <Form
      inline
      className="search-file-form"
      onSubmit={(event: React.SyntheticEvent) => event.preventDefault()}>
      <InputGroup className={'search-file-form-input-group'}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <img
              className={'search-file-form-input-icon'}
              src={AssetsConstants.SEARCH_ICON_URL}
              alt={'Search icon'}
            />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type={'text'}
          className="search-file-form-input-control"
          onChange={(event: any) => onChangeCallback(event.target.value)}
          placeholder={placeholderText ? placeholderText : 'Search file'} />
      </InputGroup>
    </Form>
  )
}