import { DatasetInformationFile } from '../types/dataverse/dataset'
import { DATASET_FILE_ORIGINAL_FORMAT_CSV } from '../utils/DatasetConstants'

export const HOST = process.env.REACT_APP_API_HOST

export class EndpointsHelper {
  public static getLatestDataverseDatasetsEndpoint(): string {
    return `${HOST}/dataverses/datasets/latest`
  }

  public static getSearchEndpoint(): string {
    return `${HOST}/search`
  }

  public static getDownloadFileEndpoint(file?: DatasetInformationFile): string {
    const fileId: number = file ? file.fileId : 0

    if (file && file.originalFileFormat === DATASET_FILE_ORIGINAL_FORMAT_CSV) {
      return `${HOST}/files/${fileId}/original`
    }

    return `${HOST}/files/${fileId}`
  }

  public static getDatasetVersions(datasetId: number): string {
    return `${HOST}/datasets/${datasetId}/versions`
  }

  public static submitNewDatasetVersion(datasetId: number, fileId: number): string {
    return `${HOST}/datasets/${datasetId}/files/${fileId}/replace`
  }

  public static getImagesInformation(datasetId: number, filename: string): string {
    return `${HOST}/datasets/${datasetId}/images?filename=${filename}`
  }

  public static userDatasetChanges(datasetId: number, rootDataFileId?: number): string {
    return `${HOST}/datasets/${datasetId}/changes${rootDataFileId ? `?rootDataFileId=${rootDataFileId}` : ''}`
  }

  public static userChanges(): string {
    return `${HOST}/datasets/changes`
  }

  public static getDatasetInformation(datasetId: number) {
    return `${process.env.REACT_APP_API_HOST}/datasets/${datasetId}/info`
  }
}