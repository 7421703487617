import React from 'react'
import { Button, Row } from 'react-bootstrap'
import deleteChangesEditorModal from '../../assets/delete_changes_editor_modal.json'
import { DatasetInformationFile } from '../../types/dataverse/dataset'
import AheadModal from './AheadModal'

interface EditorCloseModalProps {
  show: boolean
  closeButtonCallback: () => void
  deleteChangesButtonCallback?: any
  file?: DatasetInformationFile
}

class EditorDeleteChangesModal extends React.Component<EditorCloseModalProps> {
  public render() {
    return (
      <AheadModal
        show={this.props.show}
        title={deleteChangesEditorModal.title}
        body={deleteChangesEditorModal.body}
        signature={deleteChangesEditorModal.signature}
        closeButtonCallback={() => this.props.closeButtonCallback()}>
        <Row className={'ahead-modal-action-buttons'}>
          <Button
            className={'ahead-modal-action-delete-changes'}
            onClick={() => this.props.deleteChangesButtonCallback()}>Delete changes</Button>
        </Row>
      </AheadModal>
    )
  }
}

export default EditorDeleteChangesModal